import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateToken } from '../../Utils';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import { getShippersById } from '../../services/index';

const ShipperDashboard = (props) => {

  const shipperId = props.match.params.id;
  const [shipper, setShipper] = useState({});
  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    showSpinner(true);
    getShipper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShipper = async () => {
    const response = await getShippersById(shipperId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setShipper(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='main-content'>
        <Spinner display={loading}>
          <Header title='Shippers' name={shipper?.name}>
            <Link to={`/shipper/${shipperId}/users`} className='btn btn-white ml-1'>
              Users
            </Link>
            <Link to={`/shipper/${shipperId}/settings`} className='btn btn-white ml-1'>
              Settings
            </Link>
          </Header>
        </Spinner>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(ShipperDashboard);

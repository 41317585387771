/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import Modal from 'react-bootstrap/Modal';
import MaskedInput from 'react-text-mask';
import Button from 'react-bootstrap/Button';
import Input from '@material-ui/core/Input';
import StarRatings from 'react-star-ratings';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Avatar from '../../components/Avatar';
import Spinner from '../../components/Spinner';
import NumberFormat from 'react-number-format';
import ImageGallery from 'react-image-gallery';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import { isEmpty, forEach, sortBy, forIn } from 'lodash';
import CardTitle from '../../components/CardTitle';
import { GoogleApiWrapper } from 'google-maps-react';
import { npsInitialValues } from '../../constants/index';
import 'react-image-gallery/styles/css/image-gallery.css';
import { updateToken, toTimezoneConversion, getDateTimeDiffernce, getTAT, toTimeZoneDate, toTimeZone } from '../../Utils';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getUsersByCarrierId, updateShipmentDriverById, checkBatchShipment, getDistance, getPatientTrackingUrl, getPatientTrackingUrlById, getShipmentsById, getCarriersList, getHistoryById, getFeedbackById, updateCarrierShipmentById, updateShipmentStatusById, updateShipmentById, uploadImgInFirebase, addShipmentPODAttachments, getServiceTypeById, getDefaultServiceType } from '../../services/index';
import { useDropzone } from 'react-dropzone';

import moment from 'moment';
// import axios from 'axios';
// import { async } from 'q';
let QRCode = require('qrcode.react');

const BASE_URL = process.env.REACT_APP_API_URL;


const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
};


const ShipmentDetail = (props) => {
  const permissions = localStorage.getItem('ph-special-permission');
  const ship_to_validations = Yup.object().shape({
    contact_name: Yup.string()
      .required('Name is required.')
      .matches(/(\d*[a-zA-Z]+\d*)+/, 'Name cannot contain only numbers'),
    secondary_contact_name: Yup.string().matches(/(\d*[a-zA-Z]+\d*)+/, 'C/O cannot contain only numbers'),
    email: Yup.string().email('Email is invalid.'),
    //phone: Yup.string().matches(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, 'Please enter a valid phone number.'),
    //address1: Yup.string().required('Address 1 is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    postal_code: Yup.string().required('Postal Code is required.'),
  });

  const default_status = {
    status: '',
    description: '',
    pickup_date: '',
    delivery_date: '',
    driver_id: '',
  };

  const [orderList, setOrderList] = useState([{ order_number: '', product_type: '' }]);
  const [qrCode, setQrCode] = useState();
  const [view, setView] = useState(true);
  const [modalParcel, showParcelModal] = useState(false);
  const [trackUrlCalled, setTrackUrlCalled] = useState(true);
  const [driversList, setDriversList] = useState([]);
  const [shipment, setShipment] = useState({});
  const [carriers, setCarriers] = useState({});
  const [location, setLocation] = useState({});
  const [isNotify, setNotify] = useState(false);
  const [batchUpdate, setBatchUpdate] = useState(false);
  const [batchEdit, setBatchEdit] = useState(false);
  const [loading, showSpinner] = useState(false);
  const [nps, setNps] = useState(npsInitialValues);
  const [npsScore, setNpsScore] = useState(false);
  const [npsMessage, setNpsMessage] = useState('');
  const [historyList, setHistoryList] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [modalStatus, showStatusModal] = useState(false);
  const [distance, setDistance] = useState({});
  const [podSignature, setPoDSignature] = useState({});
  const [timeLine, setTimeLine] = useState('Pickup Time');
  const [modalHistory, showHistoryModal] = useState(false);
  const [pickupDate, setPickupDate] = useState(new Date());
  const [deliveryProofs, setDeliveryProofs] = useState([]);
  const [modalBilling, showBillingModal] = useState(false);
  const [modalCarrier, showCarrierModal] = useState('');
  const [modalTimeline, showTimelineModal] = useState(false);
  const [podModal, showPodModal] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState('');
  const [patientLocation, setPatientLocation] = useState({});
  const [returnedDate, setReturnedDate] = useState(new Date());
  const [modalRecipient, showRecipientModal] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [checkValidDate, setCheckValidDate] = useState('valid');
  const [isVerifiedWithOtp, setVerifiedWithOtp] = useState(false);
  const [pickupDateTime, setPickupDateTime] = useState(new Date());
  const [patientTrackingData, setPatientTrackingData] = useState([]);
  const [isBatchShipment, setIsBatchShipment] = useState([]);
  const [returnPickupDate, setReturnPickupDate] = useState(new Date());
  const [deliveryDateTime, setDeliveryDateTime] = useState(new Date());
  const [returnedDateTime, setReturnedDateTime] = useState(new Date());
  const [recipientDefaultvalues, setRecipientDefaultvalues] = useState({});
  const [phoneNumber, setPhoneNumber] = useState({ data: '', is_valid: true });
  const [returnPickupDateTime, setReturnPickupDateTime] = useState(new Date());
  const [currentShipmentStatus, setShipmentCurrentStatus] = useState(default_status);
  const [podContainer, setPodContainer] = useState()
  const [historyId, setHistoryId] = useState();
  const [imageIndex, setImageIndex] = useState(0);
  const [serviceType, setServiceType] = useState('');
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceModal,showServiceModal] = useState(false);

  useEffect(() => {
    getShipment();
    getCarriers();
    getFeedback();
  }, []);
  
  
  useEffect(()=>{
    shipment?.ship_from?.shipper_id &&  getServiceType();
  },[shipment?.ship_from?.shipper_id])

  useEffect(() => {
    getShipmentDistance();
  }, [patientLocation]);

  useEffect(() => {
    if (!modalTimeline) {
      setCheckValidDate('valid');
      setTimeLine('Pickup Time');
      setPickupDate(new Date());
      setDeliveryDate(new Date());
      setReturnPickupDate(new Date());
      setReturnedDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalTimeline]);

  useEffect(() => {
    if (!modalStatus) {
      setCheckValidDate('valid');
      setPickupDateTime(new Date());
      setDeliveryDateTime(new Date());
      setReturnPickupDateTime(new Date());
      setReturnedDateTime(new Date());
    }
  }, [modalStatus]);

  useEffect(() => {
    setRecipientDefaultvalues({ ...shipment.ship_to });
  }, [shipment.ship_to]);

  const getShipment = async () => {
    showSpinner(true);
    const response = await getShipmentsById(props.match.params.id);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shipmentData = response?.data;
        let DeliveryFee = 0;
        let PlatformFee = 0;
        if (!isEmpty(shipmentData.billing)) {
          shipmentData.billing.detailed_charges.map((entry) => {
            entry.name === 'Delivery Fee' ? (DeliveryFee = entry.amount) : (PlatformFee = entry.amount);
            return entry;
          });
          shipmentData.total_bill = {
            delivery_fee: DeliveryFee,
            platform_fee: PlatformFee,
          };
        }
        const newValue = shipmentData?.ship_to?.phone && shipmentData?.ship_to?.phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        setPhoneNumber({
          data: shipmentData?.ship_to?.phone,
          is_valid: newValue.length > 0 && newValue.length < 10 ? false : true,
        });
        await setShipment(shipmentData);
        const batchNumber = shipmentData?.batch_number > 0 ? false : true;
        await setView(batchNumber);
        const parcelItems = [];
        const parcels = !isEmpty(shipmentData?.parcels) && Object.values(shipmentData?.parcels[0])[0];
        parcels &&
          parcels.forEach((item) => {
            let parcelItem = {};
            parcelItem.product_type = item?.productType;
            parcelItem.reference_number = item?.reference_number;
            parcelItems.push(parcelItem);
          });
        setOrderList(parcelItems);
        await setPatientLocation({
          latitude: response?.data?.ship_to?.location?._latitude,
          longitude: response?.data?.ship_to?.location?._longitude,
        });
        if (trackUrlCalled) getPatientTrackingDetails(shipmentData?.shipper_id);
        setShipmentCurrentStatus({ ...currentShipmentStatus, status: shipmentData?.status, driver_id: shipmentData?.driver_id });
        const source = `${shipmentData?.ship_from?.address1}+${shipmentData?.ship_from?.address2}+${shipmentData?.ship_from?.city}+${shipmentData?.ship_from?.state}+${shipmentData?.ship_from?.postal_code}`;
        const destination = `${shipmentData?.ship_to?.address1}+ ${shipmentData?.ship_to?.address2}+${shipmentData?.ship_to?.city}+${shipmentData?.ship_to?.state}+${shipmentData?.ship_to?.postal_code}`;
        const location = {
          source: source,
          destination: destination,
        };
        setLocation(location);
        let qrCode = '';
        if (shipmentData?.batch_number > 0) {
          if (shipmentData?.order_code?.length > 0) {
            qrCode = `${shipmentData?.order_code}`;
          } else {
            qrCode = `${shipmentData?.batch_number}`;
          }
        } else {
          qrCode = `${shipmentData?.order_number}^${shipmentData?.ship_to?.contact_name}^${shipmentData?.ship_to?.phone}^${shipmentData?.ship_to?.email}^${shipmentData?.ship_to?.address1}^${shipmentData.ship_to.city}^${shipmentData?.ship_to?.state}^${shipmentData?.ship_to?.postal_code}`;
        }
        setQrCode(qrCode);
        await getStatusHistory(shipmentData);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getPatientTrackingDetails = async (id) => {
    showSpinner(true);
    const response = await getPatientTrackingUrlById(id);
    setTrackUrlCalled(false);
    if ((response.status === 200 || response.status === 201) && response?.data.length) {
      await setPatientTrackingData(response?.data);
    } else {
      const response = await getPatientTrackingUrl();
      if ((response.status === 200 || response.status === 201) && response?.data) {
        await setPatientTrackingData(response?.data);
      }
    }
    setTimeout(() => {
      showSpinner(false);
    }, 1600);
  };

  const getBatchShipmentDetails = async (batch_number, status) => {
    const response = await checkBatchShipment(batch_number, status);
    if ((response.status === 200 || response.status === 201) && response?.data.length) {
      await setIsBatchShipment(response?.data);
    }
  };

  const getShipmentDistance = async () => {
    if (!isEmpty(patientLocation)) {
      const request = {
        ship_from: {
          location: {
            _latitude: shipment?.ship_from?.location?._latitude,
            _longitude: shipment?.ship_from?.location?._longitude,
          },
          shipper_id: shipment?.shipper_id,
        },
        ship_to: {
          location: {
            _latitude: patientLocation?.latitude,
            _longitude: patientLocation?.longitude,
          },
        },
      };
      try {
        const response = await getDistance(request);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            await setDistance({ distance, ...response?.data });
          } else {
            if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
              await updateToken();
            }
            if (response?.response?.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getCarriers = async () => {
    showSpinner(true);
    const response = await getCarriersList();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCarriers(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getDrivers = async (carrierId) => {
    showSpinner(true);
    const response = await getUsersByCarrierId(carrierId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let userData = response?.data;
        userData.map((user) => {
          user.name = user.name?.toLowerCase() || '';
          return user;
        });
        userData = sortBy(response?.data, 'name');
        setDriversList(userData);
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getNpsRating = async (value) => {
    const otherPoints = nps.filter((point) => point.id > value);
    const ratedPoints = nps.filter((point) => point.id <= value);
    if (value <= 6) {
      ratedPoints.forEach((point) => {
        point.colour = 'rgb(239,83,80)';
      });
    } else if (value >= 7 && value <= 8) {
      ratedPoints.forEach((point) => {
        point.colour = 'rgb(255,167,38)';
      });
    } else if (value >= 9 && value <= 10) {
      ratedPoints.forEach((point) => {
        point.colour = 'rgb(124,179,66)';
      });
    }
    otherPoints.forEach((point) => {
      point.colour = 'rgb(203, 211, 227)';
    });
    const updatedNps = [...ratedPoints, ...otherPoints];
    setNps(updatedNps);
  };

  const getFeedback = async () => {
    showSpinner(true);
    const response = await getFeedbackById(props.match.params.id);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const data = response.data && response.data[0];
        await setFeedbackList(data);
        if (data && data?.nps && data.nps !== '') {
          setNpsScore(true);
          await getNpsRating(Number(data.nps));
          const message = Number(data.nps) > 8 ? 'Extremely Likely' : Number(data.nps) ? 'May Be' : 'Not Likely';
          setNpsMessage(message);
        }
        showSpinner(false);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getStatusHistory = async (shipment) => {
    const response = await getHistoryById(props.match.params.id);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setHistoryList(response?.data);
        const verificationCode = shipment?.verification_code;
        const isDelivered = !!response?.data.find((item) => item.status === 'Delivered');
        const message = response?.data.find((item) => item.status === 'Delivered')?.description;
        const signatureDetails = response?.data.find((item) => item.status === 'Delivered')?.pod_signature;
        if (isDelivered) {
          let proofs = [];
          let data = response?.data.find((item) => Array.isArray(item?.attachments) && item?.attachments?.length);
          if (data) {
            if (data.attachments[0].includes('{"url"')) {
              data = JSON.parse(data.attachments[0]);
              data = Object.values(data);
              proofs.push({ original: data, thumbnail: data });
              setDeliveryProofs(proofs);
            } else {
              const proofDetails = data?.attachments;
              if (Array.isArray(proofDetails) && proofDetails.length > 0) {
                forEach(proofDetails, (item) => {
                  proofs.push({ original: item, thumbnail: item });
                });
                setDeliveryProofs(proofs);
              }
            }
          }
          if (Number(message) === verificationCode) {
            setDeliveryMessage(`Verified with OTP - ${verificationCode}`);
            setVerifiedWithOtp(true);
          } else {
            setDeliveryMessage(message);
            setVerifiedWithOtp(false);
          }
          setPoDSignature(signatureDetails);
        }
        setTimeout(() => {
          showSpinner(false);
        }, 3000);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
    showSpinner(false);
  };

  const updateShipment = async () => {
    showSpinner(true);
    const response = await updateShipmentById(props.match.params.id, shipment);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        await getShipment();
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const updateRecipient = async (ship_to) => {
    if (!isEmpty(distance) && Number(distance?.shipment_miles) < Number(distance?.shipper_miles)) {
      shipment.shipment_update_notify = true;
      shipment.distance = isEmpty(distance) ? '' : distance?.shipment_miles.toString();
      shipment.ship_to = ship_to;
      shipment.ship_to.email = ship_to.email?.toLowerCase()?.trim();
      shipment.ship_to.phone = phoneNumber.data;
      setShipment(shipment);
      await updateShipment();
      showRecipientModal(false);
    } else {
      toast.error(`Order #(${shipment?.order_number}) address is more than ${distance?.shipper_miles} miles! Please choose a different address.`);
    }
  };

  const updateBilling = async (billing) => {
    shipment.billing.detailed_charges.map((entry) => {
      entry.name === 'Delivery Fee' ? (entry.amount = billing.delivery_fee) : (entry.amount = billing.platform_fee);
      return entry;
    });
    shipment.billing.total_amount = billing.delivery_fee + billing.platform_fee;
    setShipment(shipment);
    await updateShipment();
    getShipment();
    showBillingModal(false);
  };

  const updateTimeline = async () => {
    if (timeLine === 'Pickup Time') {
      shipment.pickup_date = toTimezoneConversion(pickupDate, shipment?.ship_from?.timezone);
    } else if (timeLine === 'Delivery Time') {
      shipment.delivery_date = toTimezoneConversion(deliveryDate, shipment?.ship_from?.timezone);
    } else if (timeLine === 'Return Pickup Time') {
      shipment.out_for_return_date = toTimezoneConversion(returnPickupDate, shipment?.ship_from?.timezone);
    } else if (timeLine === 'Return Time') {
      shipment.returned_date = toTimezoneConversion(returnedDate, shipment?.ship_from?.timezone);
    }
    await updateShipment();
    getShipment();
    showTimelineModal(false);
    setCheckValidDate('valid');
  };

  const updateCarrier = async (carrier) => {
    showSpinner(true);
    const response = modalCarrier === "updateDriver" ? await updateDriver(carrier.driver_id) : await updateCarrierShipmentById(props.match.params.id, carrier.id);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        showCarrierModal(false);
        await getShipment();
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const updateDriver = async (driverId) => {
    showSpinner(true);
    if (isBatchShipment?.length > 0 && batchUpdate) {
      await updateBatchDriver(driverId);
      await getShipment();
      showSpinner(false);
    } else {
      updateShipmentDriver(driverId);
    }
  };

  const updateBatchDriver = async (driverId) => {
    isBatchShipment.forEach(async (shipment) => {
      const response = await updateShipmentDriverById(shipment.id, driverId);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          showCarrierModal(false);
        } else {
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    });
  };

  const updateShipmentDriver = async (driverId) => {
    showSpinner(true);
    const response = await updateShipmentDriverById(props.match.params.id, driverId);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        showCarrierModal(false);
        await getShipment();
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const updateStatus = async (status) => {
    if (status.status === 'Exception') {
      await confirmShipmentCancel(status);
    } else {
      showSpinner(true);
      let url = `shipment/${props.match.params.id}/status`;
      let data = status;
      if ((status.status === 'Delivered' || status.status === 'Returned' || status.status === 'Out for Return')) {
        if (status.driver_id?.length > 0 && shipment.driver?.id !== status.driver_id) {
          updateDriver(status.driver_id);
        }
      }
      if (status.status === 'Out for Delivery') {
        if (batchUpdate) {
          url = `shipment/${props.match.params.id}/out-for-delivery/batch?status=${shipment?.status?.toLowerCase()}`;
        } else {
          url = `shipment/${props.match.params.id}/out-for-delivery`;
        }
        data.pickup_date = toTimezoneConversion(pickupDateTime, shipment?.ship_from?.timezone);
      } else if (status.status === 'Delivered') {
        if (batchUpdate) {
          url = `shipment/${props.match.params.id}/delivered/batch?status=${shipment?.status?.toLowerCase()}`;
        } else {
          url = `shipment/${props.match.params.id}/delivered`;
        }
        data.delivery_date = toTimezoneConversion(deliveryDateTime, shipment?.ship_from?.timezone);
      } else if (status.status === 'Out for Return') {
        if (batchUpdate) {
          url = `shipment/${props.match.params.id}/out-for-return/batch?status=${shipment?.status?.toLowerCase()}`;
        } else {
          url = `shipment/${props.match.params.id}/out-for-return`;
        }
        data.out_for_return_date = toTimezoneConversion(returnPickupDateTime, shipment?.ship_from?.timezone);
      } else if (status.status === 'Returned') {
        if (batchUpdate) {
          url = `shipment/${props.match.params.id}/returned/batch?status=${shipment?.status?.toLowerCase()}`;
        } else {
          url = `shipment/${props.match.params.id}/returned`;
        }
        data.returned_date = toTimezoneConversion(returnedDateTime, shipment?.ship_from?.timezone);
      } else if (status.status === 'In Transit') {
        url = `shipment/${props.match.params.id}/in-transit`
      }
      data.notify = isNotify;
      const request = { ...data };
      delete request.driver_id;
      const response = await updateShipmentStatusById(url, request);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          if (status.status === 'Out for Delivery' && status.driver_id?.length > 0) {
            updateDriver(status.driver_id);
          }
          showStatusModal(false);
          getShipment();
          getStatusHistory();
          showSpinner(false);
          setBatchUpdate(false);
          setNotify(true);
          setPickupDateTime(new Date());
          setDeliveryDateTime(new Date());
          setReturnPickupDateTime(new Date());
          setReturnedDateTime(new Date());
          setCheckValidDate('valid');
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(false);
          setBatchUpdate(false);
          setNotify(true);
          setPickupDateTime(new Date());
          setDeliveryDateTime(new Date());
        }
      }
    }
  };

  const confirmShipmentCancel = (status) => {
    let url = `shipment/${props.match.params.id}/status`;
    if (batchUpdate) {
      url = `shipment/${props.match.params.id}/exception/batch?status=${shipment?.status?.toLowerCase()}`;
    } else {
      url = `shipment/${props.match.params.id}/exception`;
    }
    let data = status;
    data.notify = isNotify;
    const request = { ...data };
    delete request.driver_id;
    swal({
      title: `Cancel`,
      text: `Do you want to cancel the shipment ${shipment?.number}`,
      buttons: ['No', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await updateShipmentStatusById(url, request);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            showStatusModal(false);
            getShipment();
            getStatusHistory();
            showSpinner(false);
            setBatchUpdate(false);
            setNotify(true);
            setCheckValidDate('valid');
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
            setBatchUpdate(false);
            setNotify(true);
          }
        }
      } else {
        showSpinner(false);
      }
    });
  };

  const updateHistory = async (status) => {
    let url;
    if (batchEdit) {
      isBatchShipment.forEach(async (batch, index) => {
        url = `shipment/${batch.id}/status-history`;
        const editStatusCode = await editDescription(url, status);
        if (index === 0 && editStatusCode) {
          showHistoryModal(false);
          setBatchEdit(false);
          getShipment();
          getStatusHistory();
          if ((status.status === 'Out for Delivery' || status.status === 'Delivered' || status.status === 'Out for Return' || status.status === 'Returned') && status.driver_id?.length > 0) {
            updateBatchDriver(status.driver_id);
          }
        }
      });
    } else {
      url = `shipment/${props.match.params.id}/status-history`;
      const editStatusCode = await editDescription(url, status);
      if (editStatusCode) {
        showHistoryModal(false);
        setBatchEdit(false);
        getShipment();
        getStatusHistory();
      }
      if ((status.status === 'Out for Delivery' || status.status === 'Delivered' || status.status === 'Out for Return' || status.status === 'Returned') && status.driver_id?.length > 0) {
        updateShipmentDriver(status.driver_id);
      }
    }

    showSpinner(false);
  };

  const editDescription = async (url, status) => {
    const data = { description: status.description, status: status.status };
    const response = await updateShipmentStatusById(url, data);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        return true;
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        return false;
      }
    }
  };

  const handlePhoneChange = ({ target: { value } }) => {
    const newValue = value && value.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    setPhoneNumber({
      data: value,
      is_valid: newValue.length > 0 && newValue.length < 10 ? false : true,
    });
  };

  const handleDateChange = (date, name) => {
    if (name === 'pickupDateTime') {
      setPickupDateTime(date);
      const TAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.create_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = TAT <= 0 ? 'Order Pickup date must be greater than created date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    } else if (name === 'deliveryDateTime') {
      setDeliveryDateTime(date);
      const TAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = TAT <= 0 ? 'Order Delivery date must be greater than pickup date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    } else if (name === 'returnPickupDateTime') {
      setReturnPickupDateTime(date);
      const TAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = TAT <= 0 ? 'Order Return Pickup date must be greater than pickup date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    } else if (name === 'returnedDateTime') {
      setReturnedDateTime(date);
      const PickupTAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone));
      const ReturnPickupTAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.out_for_return_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = PickupTAT <= 0 || ReturnPickupTAT <= 0 ? 'Returned date must be greater than pickup and return pick up date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    } else if (name === 'pickupDate') {
      setPickupDate(date);
      const TAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.create_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = TAT <= 0 ? 'Order Pickup date must be greater than created date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    } else if (name === 'deliveryDate') {
      setDeliveryDate(date);
      const TAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = TAT <= 0 ? 'Order Delivery date must be greater than pickup date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    } else if (name === 'returnPickupDate') {
      setReturnPickupDate(date);
      const TAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = TAT <= 0 ? 'Order Return Pickup date must be greater than pickup date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    } else if (name === 'returnedDate') {
      setReturnedDate(date);
      const PickupTAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone));
      const ReturnPickupTAT = getTAT(moment(date).format('lll'), toTimeZone(shipment?.out_for_return_date, 'lll', shipment?.ship_from?.timezone));
      const validityCheckMessage = PickupTAT <= 0 || ReturnPickupTAT <= 0 ? 'Returned date must be greater than pickup and return pick up date' : 'valid';
      setCheckValidDate(validityCheckMessage);
    }
  };

  const handleTimeLineChange = (e) => {
    setTimeLine(e.target.value);
    setCheckValidDate('valid');
    setPickupDate(new Date());
    setDeliveryDate(new Date());
    setReturnPickupDate(new Date());
    setReturnedDate(new Date());
  };

  const handleAddressSelect = async (value) => {
    const selectedAddress = await geocodeByAddress(value);
    const LatLong = await getLatLng(selectedAddress[0]);
    let street_number = '';
    let sub_locality1 = '';
    let sub_locality2 = '';
    let sub_locality3 = '';
    let sub_locality4 = '';
    let route = '';
    let city = '';
    let state = '';
    let postal_code = '';
    let location = {};
    if (LatLong) {
      location._latitude = LatLong.lat;
      location._longitude = LatLong.lng;
      await setPatientLocation({
        latitude: LatLong.lat,
        longitude: LatLong.lng,
      });
    }
    selectedAddress[0].address_components.length &&
      selectedAddress[0].address_components.forEach((address) => {
        if (address.types.includes('street_number')) street_number = address.short_name;
        if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
        if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
        if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
        if (address.types.includes('country')) sub_locality4 = address.long_name;
        if (address.types.includes('route')) route = address.short_name;
        if (address.types.includes('locality')) city = address.short_name;
        if (address.types.includes('administrative_area_level_1')) state = address.short_name;
        if (address.types.includes('postal_code')) postal_code = address.long_name;
      });

    setRecipientDefaultvalues({
      ...recipientDefaultvalues,
      address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route} ${sub_locality4}`,
      postal_code,
      city,
      state,
      location,
    });
  };

  const autoHandleChange = (address) => {
    setRecipientDefaultvalues({
      ...recipientDefaultvalues,
      address1: address,
    });
  };

  const resetAddress = () => {
    showRecipientModal(false);
    setRecipientDefaultvalues(shipment.ship_to);
    setDistance({});
  };

  const toggleModal = async (name) => {
    if (name === 'pod') {
      showPodModal(true)
    } else if (name === 'recipient') {
      await getShipmentDistance();
      showRecipientModal(true);
    } else if (name === 'status' || name === 'history') {
      if (shipment?.carrier_id) {
        await getDrivers(shipment.carrier_id);
      }
      if (shipment?.batch_number > 0) getBatchShipmentDetails(shipment?.batch_number, shipment?.status);
      if (name === 'history') {
        showHistoryModal(true);
      }
      if (name === 'status') {
        showStatusModal(true);
      }
    }
  };



  // Image Upload Code Start ----
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    // maxFiles: 4,
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    onDrop: acceptedFiles => {
      setFiles(files.concat(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))));

    }
  });


  useEffect(() => { return () => files.forEach(file => URL.revokeObjectURL(file.preview)); }, [files]);
  const removeImage = (index) => setFiles(file => file.filter((s, i) => (i !== index)))


  const handlePODUpdate = async () => {
    showPodModal(false)
    let attachments = []

    for (let i = 0; i < deliveryProofs.length; i++) {
      attachments.push(deliveryProofs[i].original)
    }

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let fileData = new FormData();
        fileData.append('file', files[i]);
        const response = await uploadImgInFirebase(fileData)
        if (response) {
          attachments.push(response.data.url)
        }
      }
    }
    if (attachments.length > 0) {
      let historyID = historyList.filter((item) => {
        item = item.status === "Delivered"
        return item
      })
      setHistoryId(historyID[0]?.id)
      await addShipmentPODAttachments({ attachments }, historyID[0]?.id)
        .then(() => {
          getStatusHistory();
          setFiles([])
          toast.success('POD updated successfully!!')
        })
        .catch(() => toast.error('Something went wrong!!'))
    }

  }

  const handleSlide = currentIndex => { setImageIndex(currentIndex) };

  const removeImg = async () => {
    swal({
      text: `Do you want to remove the Image?`,
      buttons: ['No', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        let filtereDeliveryProofs = deliveryProofs.filter((item, index) => {
          return item = index !== imageIndex
        })

        let historyID = historyList.filter((item) => {
          item = item.status === "Delivered"
          return item
        })
        let attachments = [];
        for (let i = 0; i < filtereDeliveryProofs.length; i++) {
          attachments.push(filtereDeliveryProofs[i].original)
        }
        await addShipmentPODAttachments({ attachments }, historyID[0]?.id)
          .then(() => {
            toast.success('POD updated successfully!!')
            window.location.reload();
          })
          .catch(() => toast.error('Something went wrong!!'))
      }
    });
  }

    //#region service Type

  const getServiceType = async () => {
    setServiceType('');
    showSpinner(true);
    try {
      const response = await getServiceTypeById(shipment?.ship_from?.shipper_id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          const serviceTypes = await sortBy(response?.data, 'sort_order');
          let filteredService = serviceTypes.filter((item)=> item?.service_type?.toLowerCase() !== 'future' )
          setServiceType(serviceTypes && serviceTypes[0]?.name);
          setServiceTypeList(filteredService); 
          showSpinner(false);
        } else {
          const defaultServiceResponse = await getDefaultServiceType();
          const defaultServiceTypes = await sortBy(defaultServiceResponse?.data, 'sort_order');
          setServiceType(defaultServiceTypes && defaultServiceTypes[0]?.name);
          setServiceTypeList(defaultServiceTypes);
          showSpinner(false);
        }
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeService = (data)=>{
      let newService = serviceTypeList.find((item) => item.name.toLowerCase() === data?.toLowerCase());
      if (newService) {
        setShipment((prev) => ({
          ...prev,
          service_type: newService.name,
          service_type_id: newService.id
        }));
      } else {
        console.error("Service type not found in serviceTypeList:", data?.service_type);
      }
  }

  const handleServiceTypeUpdate = async () => {
    await updateShipment()
    showServiceModal(false);
 };

  const fedExServiceType = shipment?.service_type === 'GROUND_HOME_DELIVERY' ? 'Ground Home Delivery' : shipment?.service_type === 'STANDARD_OVERNIGHT' ? 'Standard Overnight' : shipment?.service_type;

  return (
    <>
      <Spinner display={loading}>
        <Navbar />
        <div className="main-content">
          <Header title="Shipment" name={shipment.number}>
            <div className="text-right">
              <h6 className="header-pretitle">Status</h6>
              <h1 className="header-title">
                {patientTrackingData[0]?.value && (
                  <a href={`${patientTrackingData[0]?.value}/${shipment.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-light" data-toggle="tooltip" title="Route summary">
                    <img src={shipment?.status === 'Returned' ? '/img/return_marker.png' : shipment?.status === 'Out for Return' ? '/img/out_for_return.png' : shipment?.status === 'Out for Delivery' ? '/img/out_for_delivery.png' : shipment?.status === 'Delivered' ? '/img/delivered.png' : shipment?.status === 'Exception' ? '/img/cancelled.png' : shipment?.status === 'In Transit' ? '/img/out_for_delivery.png' : '/img/ready_for_pickup.png'} alt="Route summary" className="d-none d-sm-block" />
                  </a>
                )}
                &nbsp; {shipment?.status === 'Exception' ? 'Cancelled' : shipment?.status}
              </h1>
            </div>
          </Header>
          <Container>
            <Row>
              <Col>
                <Card>
                  <CardTitle title="Sender" />
                  <Card.Body>
                    {shipment?.ship_from && (
                      <>
                        <div>{shipment?.ship_from.shipper_name}</div>
                        <div className="text-muted">
                          <div>{shipment?.ship_from.address1}</div>
                          <div>{shipment?.ship_from.address2}</div>
                          <div>
                            {shipment?.ship_from.city}, {shipment?.ship_from.state} {shipment?.ship_from.postal_code}
                          </div>
                          <a href={`tel:${shipment?.ship_from?.phone}`}> {shipment?.ship_from?.phone} </a>
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>
                <Card>
                  <CardTitle title="Billing">
                    <Button disabled={isEmpty(shipment?.billing)} size="sm" variant="light" onClick={() => showBillingModal(true)}>
                      Update
                    </Button>
                  </CardTitle>
                  <Card.Body>
                    {shipment.billing && !isEmpty(shipment.billing) && (
                      <ListGroup variant="flush">
                        {shipment.billing.detailed_charges &&
                          shipment.billing.detailed_charges.map((charge, index) => {
                            return (
                              <ListGroup.Item key={index}>
                                <Row>
                                  <Col>{charge.name}</Col>
                                  <Col sm="auto">
                                    <NumberFormat value={charge.amount} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            );
                          })}
                        <ListGroup.Item>
                          <Row>
                            <Col>Total</Col>
                            <Col sm="auto">
                              <NumberFormat value={shipment.billing.total_amount} displayType={'text'} thousandSeparator={true} prefix={'$'}></NumberFormat>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </Card.Body>
                </Card>
                <Card>
                <CardTitle title="Delivery Services">
                    <Button  size="sm" variant="light" onClick={() => showServiceModal(true)}>
                      Update
                    </Button>
                  </CardTitle>
                  <Card.Body>
                    {shipment && (
                      <ListGroup variant="flush">
                        <ListGroup.Item key={1}>
                          <Row className='align-items-center"'>
                            <Col>Service Type</Col>
                            <Col sm="auto" className="text-muted">
                              {fedExServiceType}
                            </Col>
                          </Row>
                          <Row className='align-items-center"'>
                            <Col></Col>
                            <Col sm="auto" className="text-muted">
                              {shipment?.service_type?.toLowerCase() === 'future' && <small>{moment(shipment?.estimated_delivery_date).format('MMM DD, YYYY')}</small>}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        {shipment?.delivery_timeslot?.length > 0 && (
                          <ListGroup.Item key={2}>
                            <Row className='align-items-center"'>
                              <Col>Time Slot</Col>
                              <Col sm="auto" className="text-muted">
                                {shipment.delivery_timeslot}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        <ListGroup.Item key={3}>
                          <Row>
                            <Col>
                              {'Parcels'} {!isEmpty(shipment?.parcels) && <i className="fe fe-eye mt-5 ml-2" onClick={() => showParcelModal(true)}></i>}
                            </Col>
                            <Col sm="auto" className="text-muted">
                              {shipment?.parcels && shipment?.parcels.length > 0 && Object.values(shipment?.parcels[0])[0]?.length}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item key={4}>
                          <Row>
                            <Col>{'Storage Type'}</Col>
                            <Col sm="auto" className="text-muted">
                              {shipment.storage_type}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item key={5}>
                          <Row>
                            <Col>Special instructions</Col>
                            <Col sm="auto" className="text-muted">
                              {shipment.delivery_instructions}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardTitle title="Recipient">
                    <Button size="sm" variant="light" onClick={() => toggleModal('recipient')}>
                      Update
                    </Button>
                  </CardTitle>
                  <Card.Body>
                    {shipment.ship_to && (
                      <>
                        <div>{shipment.ship_to.company_name}</div>
                        <div>{shipment.ship_to?.secondary_contact_name ? `${shipment.ship_to.contact_name} C/O ${shipment.ship_to?.secondary_contact_name}` : shipment.ship_to.contact_name}</div>
                        <div className="text-muted">
                          <div>{shipment.ship_to.address1}</div>
                          <div>{shipment.ship_to.address2}</div>
                          <div>
                            {shipment.ship_to.city}, {shipment.ship_to.state} {shipment.ship_to.postal_code}
                          </div>
                          <div>
                            {shipment?.distance?.length > 0 && !isEmpty(location) && location?.source && location?.destination && (
                              <span className="text-success">
                                Delivery distance:
                                <a href={`https://maps.google.com?saddr=${location?.source}&daddr=${location?.destination}&directionsmode=driving&dir_action=navigate`} target=" _blank" className="text-success">
                                  {shipment?.distance} miles.
                                </a>
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>
                {!isEmpty(feedbackList) && (
                  <Card>
                    <CardTitle title="Feedback" />
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <Row>
                            <Col sm="auto" className="pr-0">
                              <Avatar />
                            </Col>
                            <Col>
                              <h4 className="mb-1">{feedbackList.user_name}</h4>
                              <p className="small text-muted mb-0">{toTimeZoneDate(feedbackList?.create_date, 'LLL', shipment?.ship_from?.timezone)}</p>
                            </Col>
                          </Row>
                          <div className="small mt-2">{feedbackList.comment}</div>
                          <div className="mt-2">
                            <StarRatings rating={feedbackList.rating} starRatedColor="rgb(246,195,67)" starDimension="16px" numberOfStars={5} name="rating" />
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                )}
                {!isEmpty(feedbackList) && npsScore && (
                  <Card>
                    <CardTitle title="NPS Score" />
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <Row>
                            <Col sm="auto" className="pr-0">
                              <Avatar />
                            </Col>
                            <Col>
                              <h4 className="mb-1">{feedbackList.user_name}</h4>
                              <p className="small text-muted mb-0">{toTimeZoneDate(feedbackList?.create_date, 'LLL', shipment?.ship_from?.timezone)}</p>
                            </Col>
                          </Row>
                          <div className="small mt-3">{npsMessage}</div>
                          <div className="mt-3">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination justify-content-center">
                                {nps.map((item) => {
                                  return (
                                    <li key={item.id}>
                                      <span style={{ backgroundColor: item.colour, cursor: 'pointer' }} className="page-link">
                                        {item.id}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </nav>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                )}
                <Card>
                  <CardTitle title="Timeline">
                    <Button size="sm" variant="light" onClick={() => showTimelineModal(true)}>
                      Update
                    </Button>
                  </CardTitle>
                  <Card.Body>
                    {shipment && (
                      <ListGroup variant="flush">
                        <ListGroup.Item key={1}>
                          <Row className='align-items-center"'>
                            <Col>Order Time</Col>
                            <Col sm="auto" className="text-muted">
                              {toTimeZoneDate(shipment?.create_date, 'lll', shipment?.ship_from?.timezone)}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        {shipment?.pickup_date !== null && (
                          <ListGroup.Item key={2}>
                            <Row className='align-items-center"'>
                              <Col>Pickup Time</Col>
                              <Col sm="auto" className="text-muted">
                                {shipment.pickup_date && toTimeZoneDate(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone)}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {shipment?.delivery_date !== null && (
                          <ListGroup.Item key={3}>
                            <Row>
                              <Col>Delivery Time</Col>
                              <Col sm="auto" className="text-muted">
                                {shipment.delivery_date && toTimeZoneDate(shipment?.delivery_date, 'lll', shipment?.ship_from?.timezone)}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {shipment?.out_for_return_date !== null && (
                          <ListGroup.Item key={4}>
                            <Row>
                              <Col>Return Pickup Time</Col>
                              <Col sm="auto" className="text-muted">
                                {shipment.out_for_return_date && toTimeZoneDate(shipment?.out_for_return_date, 'lll', shipment?.ship_from?.timezone)}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {shipment?.returned_date !== null && (
                          <ListGroup.Item key={5}>
                            <Row>
                              <Col>Return Time</Col>
                              <Col sm="auto" className="text-muted">
                                {shipment.returned_date && toTimeZoneDate(shipment?.returned_date, 'lll', shipment?.ship_from?.timezone)}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        <ListGroup.Item key={6}>
                          <Row>
                            <Col>Estimated Delivery</Col>
                            <Col sm="auto" className="text-muted">
                              {shipment.estimated_delivery_timeslot}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item key={7}>
                          <Row>
                            <Col>Pickup to Delivery</Col>
                            <Col sm="auto" className="text-muted">
                              {shipment.delivery_date && shipment.pickup_date ? getDateTimeDiffernce(toTimeZone(shipment?.delivery_date, 'lll', shipment?.ship_from?.timezone), toTimeZone(shipment?.pickup_date, 'lll', shipment?.ship_from?.timezone)) : ''}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item key={8}>
                          <Row>
                            <Col>Turn Around Time</Col>
                            <Col sm="auto" className="text-muted">
                              {shipment.delivery_date && shipment.create_date ? getDateTimeDiffernce(toTimeZone(shipment?.delivery_date, 'lll', shipment?.ship_from?.timezone), toTimeZone(shipment?.create_date, 'lll', shipment?.ship_from?.timezone)) : ''}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardTitle title="Courier">
                    {shipment?.carrier?.name !== "US Post Office" && (<>
                      <Button size="sm" variant="light" onClick={() => { showCarrierModal("updateCourier"); getDrivers(shipment.carrier_id); }}>
                        Update
                      </Button>
                      <span>
                        <i className="fe fe-edit ml-4" onClick={() => { showCarrierModal("updateDriver"); getDrivers(shipment.carrier_id); }}></i>
                      </span>
                    </>
                    )}
                  </CardTitle>
                  <Card.Body>
                    {shipment.order_number && shipment.order_number.length > 0 && (
                      <>
                        <div>
                          <span>{shipment.batch_number && shipment.batch_number > 0 ? 'Reference No' : 'Order No'} </span> <span className="small text-muted">{shipment.order_number}</span>
                        </div>
                      </>
                    )}
                    {shipment?.order_code && shipment?.order_code.length > 0 && (
                      <>
                        <div>
                          <span>{'Order No'} </span> <span className="small text-muted">{shipment.order_code}</span>
                        </div>
                      </>
                    )}
                    {shipment.batch_number && shipment.batch_number > 0 ? (
                      <>
                        <span>Batch No </span> <span className="small text-muted">{shipment.batch_number}</span>
                      </>
                    ) : (
                      ''
                    )}
                    <div className="mt-2"> {shipment.carrier && <div>{shipment.carrier?.name}</div>}</div>
                    {shipment.ext_tracking_number && (
                      <>
                        <div>
                          <span>Tracking Number </span> <span className="small text-muted">{shipment.ext_tracking_number}</span>
                        </div>
                      </>
                    )}

                    {qrCode && <p className="text-center mt-3">{<QRCode value={qrCode} />}</p>}
                  </Card.Body>
                </Card>
                <Card>
                  <CardTitle title="Proof of delivery" >
                    {shipment?.status === 'Delivered' &&
                      <Button size="sm" variant="light" onClick={() => toggleModal('pod')}>
                        Upload
                      </Button>

                    }
                  </CardTitle>
                  <Card.Body>
                    <div className="text-center mb-2">{isVerifiedWithOtp && <h3 className="header-title">{deliveryMessage?.length > 0 ? deliveryMessage : ''}</h3>}</div>

                    {!isEmpty(podSignature) && (
                      <>
                        <div>
                          <span>Name </span> <span className="small text-muted">{podSignature?.name}</span>
                        </div>
                        <div className="mt-2">
                          <span>Relationship </span> <span className="small text-muted">{podSignature?.relation_ship}</span>
                        </div>
                      </>
                    )}
                    <div className="position-relative">

                      {shipment?.status === 'Delivered' && deliveryProofs?.length !== 0 &&
                        <i className='fe fe-x-circle img-delete-btn p-1 position-absolute top-0 right-0' onClick={() => removeImg()} > </i>
                      }

                      {deliveryProofs?.length > 0 ?

                        <ImageGallery items={deliveryProofs} showBullets={false} showThumbnails={false} lazyLoad={true} showPlayButton={false} onSlide={handleSlide}>
                          <h1>hello</h1>
                        </ImageGallery >
                        : !isVerifiedWithOtp && deliveryMessage?.length > 0 ? deliveryMessage : ''}
                    </div>
                  </Card.Body>
                </Card>
                <Card>
                  <CardTitle title="Recent Activity">
                    {permissions === 'super-admin' ? (
                      <Button size="sm" variant="light" onClick={() => toggleModal('status')}>
                        Update
                      </Button>
                    ) : (
                      shipment.status !== 'Returned' &&
                      shipment.status !== 'Delivered' && (
                        <Button size="sm" variant="light" onClick={() => toggleModal('status')}>
                          Update
                        </Button>
                      )
                    )}
                    <span>
                      <i className="fe fe-edit ml-4" onClick={() => toggleModal('history')}></i>
                    </span>
                  </CardTitle>
                  <Card.Body>
                    <ListGroup variant="flush">
                      {historyList &&
                        historyList.map((status, index) => {
                          return (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col sm="auto">
                                  <Avatar image={status.user_photo_url} />
                                </Col>
                                <Col>
                                  {status.user_name} <i className="fe fe-arrow-right" /> {status?.status === 'Exception' ? 'Cancelled' : status?.status}
                                  <div className="small text-muted">{status.description}</div>
                                  <div className="small text-muted"> {toTimeZoneDate(status?.create_date, 'LLL', shipment?.ship_from?.timezone)}</div>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          );
                        })}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Spinner >
      <Modal show={modalRecipient} onHide={() => showRecipientModal(false)} backdrop="static">
        <Modal.Body>
          <Modal.Title>
            <Row>
              <Col xs={6}>
                <h1>Recipient</h1>
              </Col>
              <Col xs={6} className="text-right">
                <Button disabled={loading} variant="outline-secondary" onClick={resetAddress}>
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Title>
          <hr />
          <Formik enableReinitialize={true} initialValues={recipientDefaultvalues} validationSchema={ship_to_validations} onSubmit={updateRecipient}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control type="text" name="company_name" placeholder="e.g. ABC Company" value={values.company_name} onChange={handleChange} isInvalid={!!errors.company_name} />
                  <Form.Control.Feedback type="invalid">{errors.company_name}</Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Contact Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="text" name="contact_name" placeholder="e.g. John Doe" value={values.contact_name} onChange={handleChange} isInvalid={!!errors.contact_name} />
                      <Form.Control.Feedback type="invalid">{errors.contact_name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>C/O</Form.Label>
                      <Form.Control type="text" name="secondary_contact_name" placeholder="e.g. Jane Doe" value={values.secondary_contact_name} onChange={handleChange} isInvalid={!!errors.secondary_contact_name} />
                      <Form.Control.Feedback type="invalid">{errors.secondary_contact_name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" name="email" placeholder="e.g. john@phoxhealth.com" value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Phone</Form.Label>
                      <Input error={phoneNumber.is_valid ? false : true} type="tel" className="form-control" id="formatted-text-mask-input" value={phoneNumber.data} onChange={handlePhoneChange} name="textmask" placeholder="( _ _ _ ) _ _ _ - _ _ _ _" inputComponent={TextMaskCustom} />
                      {!phoneNumber.is_valid && <span className="text-danger">Please enter a valid phone number.</span>}
                    </Form.Group>
                  </Col>
                </Row>

                <hr />

                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Address Line 1<span className="text-danger">*</span>
                      </Form.Label>
                      <PlacesAutocomplete name="address1" value={values.address1} onChange={autoHandleChange} onSelect={handleAddressSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                          <div className="search-input-container col-12 p-0 mb-4">
                            <Form.Control {...getInputProps({ placeholder: 'e.g. 100 W Main St.', className: 'form-control business-form' })} isInvalid={!!errors.address1} />
                            <Form.Control.Feedback type="invalid">{errors.address1}</Form.Control.Feedback>
                            <div className="autocomplete-container bg-light">
                              {suggestions.map((suggestion) => {
                                const className = 'suggestion-item';
                                return (
                                  <div {...getSuggestionItemProps(suggestion, { className })}>
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Address Line 2</Form.Label>
                      <Form.Control type="text" name="address2" placeholder="e.g. Suite 102" value={values.address2} onChange={handleChange} isInvalid={!!errors.address2} />
                      <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        City<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="text" name="city" placeholder="e.g. Seattle" value={values.city} onChange={handleChange} isInvalid={!!errors.city} />
                      <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        State<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="text" name="state" placeholder="e.g. WA" value={values.state} onChange={handleChange} isInvalid={!!errors.state} />
                      <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Postal Code<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="text" maxLength="5" name="postal_code" placeholder="e.g. 21044" value={values.postal_code} onChange={handleChange} isInvalid={!!errors.postal_code} />
                      <Form.Control.Feedback type="invalid">{errors.postal_code}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {view && !isEmpty(distance) && (
                  <span className={Number(distance?.shipment_miles) < Number(distance?.shipper_miles) ? 'text-success' : 'text-danger'}>
                    {' '}
                    <i className="fe fe-info" />
                    &nbsp;{`Delivery distance is ${distance?.shipment_miles} miles.`}
                  </span>
                )}
                <br />
                <Button type="submit" className="mt-4" disabled={!phoneNumber.is_valid || loading} variant="primary">
                  Update
                </Button>
                <Button disabled={loading} variant="outline-secondary" onClick={resetAddress} className="ml-2 mt-4">
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={modalCarrier} onHide={() => showCarrierModal(false)}>
        <Modal.Body>
          <Modal.Title className="h1">Courier</Modal.Title>
          <hr />
          <Formik initialValues={shipment.carrier} onSubmit={updateCarrier}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control as="select" name="id" custom value={values.id} onChange={handleChange} disabled={modalCarrier === "updateDriver"} >
                    <option value="-">-- Select Courier --</option>
                    {carriers.map((carrier, index) => {
                      return (
                        <option value={carrier.id} key={index}>
                          {carrier.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                {!isEmpty(driversList) && values && modalCarrier === "updateDriver" && (
                  <Form.Group>
                    <Form.Label>Driver</Form.Label>
                    <Form.Control as="select" name="driver_id" custom defaultValue={shipment.driver_id} onChange={handleChange} disabled={modalCarrier === "updateCourier"} >
                      <option value="-">-- Select --</option>
                      {driversList.map((driver, index) => {
                        return (
                          <option value={driver.id} key={index}>
                            {driver.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                )}
                <Button disabled={loading} type="submit" variant="primary">
                  Update
                </Button>
                <Button
                  disabled={loading}
                  variant="outline-secondary"
                  onClick={() => {
                    showCarrierModal(false);
                  }}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalBilling} onHide={() => showBillingModal(false)}>
        <Modal.Body>
          <Modal.Title className="h1">Billing</Modal.Title>
          <hr />
          <Formik initialValues={shipment.total_bill} enableReinitialize={true} onSubmit={updateBilling}>
            {({ handleChange, handleSubmit, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Delivery Fee</Form.Label>
                  <Form.Control type="number" name="delivery_fee" placeholder="e.g. 5" value={values.delivery_fee} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Platform Fee</Form.Label>
                  <Form.Control type="number" name="platform_fee" placeholder="e.g. 5" value={values.platform_fee} onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Total</Form.Label>
                  <Form.Control disabled type="number" name="total" placeholder="e.g. 5" value={values.delivery_fee + values.platform_fee} />
                </Form.Group>
                <hr />
                <Button disabled={loading} type="submit" variant="primary">
                  Update
                </Button>
                <Button
                  disabled={loading}
                  variant="outline-secondary"
                  onClick={() => {
                    showBillingModal(false);
                  }}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalStatus} onHide={() => showStatusModal(false)}>
        <Modal.Body>
          <Modal.Title className="h1">Update Status</Modal.Title>
          <hr />
          <Formik initialValues={currentShipmentStatus} onSubmit={updateStatus}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  {permissions === 'super-admin' ? (
                    <Form.Control as="select" name="status" custom value={values.status} onChange={handleChange}>
                      <option value="Ready for Pickup">Ready for Pickup</option>
                      <option value="In Transit">In Transit</option>
                      <option value="Out for Delivery">Out for Delivery</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Exception">Cancel</option>
                      <option value="Out for Return">Out for Return</option>
                      <option value="Returned">Returned</option>
                    </Form.Control>
                  ) : (
                    <Form.Control as="select" name="status" custom value={values.status} onChange={handleChange}>
                      {shipment.status !== 'Out for Return' && shipment.status !== 'Exception' && shipment.status !== 'Delivered' && shipment.status !== 'Out for Delivery' && <option value="Ready for Pickup">Ready for Pickup</option>}
                      {shipment.status !== 'Out for Return' && shipment.status !== 'Exception' && shipment.status !== 'Delivered' && <option value="In Transit">In Transit</option>}
                      {shipment.status !== 'Out for Return' && shipment.status !== 'Exception' && shipment.status !== 'Delivered' && <option value="Out for Delivery">Out for Delivery</option>}
                      {shipment.status !== 'Out for Return' && shipment.status !== 'Exception' && <option value="Delivered">Delivered</option>}
                      {shipment.status !== 'Out for Return' && shipment.status !== 'Out for Delivery' && <option value="Exception">Cancel</option>}
                      {shipment.status !== 'Ready for Pickup' && <option value="Out for Return">Out for Return</option>}
                      {shipment.status !== 'Ready for Pickup' && <option value="Returned">Returned</option>}
                    </Form.Control>
                  )}
                </Form.Group>
                {isBatchShipment.length > 1 && (
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" disabled={values?.status === shipment?.status} label={`Apply to Batch ${shipment?.batch_number}`} checked={batchUpdate} onChange={(e) => setBatchUpdate(e.target.checked)} />
                  </Form.Group>
                )}
                {values.status === 'Out for Delivery' && values?.status !== shipment?.status && (
                  <>
                    <Form.Group>
                      <Form.Label>Pickup Time</Form.Label>
                      <br />
                      <DatePicker name="pickupDateTime" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={pickupDateTime} onChange={(date) => handleDateChange(date, 'pickupDateTime')} />
                    </Form.Group>
                  </>
                )}
                {values.status === 'Delivered' && values?.status !== shipment?.status && (
                  <>
                    <Form.Group>
                      <Form.Label>Delivery Time</Form.Label>
                      <br />
                      <DatePicker name="deliveryDateTime" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={deliveryDateTime} onChange={(date) => handleDateChange(date, 'deliveryDateTime')} />
                    </Form.Group>
                  </>
                )}
                {values.status === 'Out for Return' && values?.status !== shipment?.status && (
                  <>
                    <Form.Group>
                      <Form.Label>Return Pickup Time</Form.Label>
                      <br />
                      <DatePicker name="returnPickupDateTime" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={returnPickupDateTime} onChange={(date) => handleDateChange(date, 'returnPickupDateTime')} />
                    </Form.Group>
                  </>
                )}
                {values.status === 'Returned' && values?.status !== shipment?.status && (
                  <>
                    <Form.Group>
                      <Form.Label>Return Time</Form.Label>
                      <br />
                      <DatePicker name="returnedDateTime" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={returnedDateTime} onChange={(date) => handleDateChange(date, 'returnedDateTime')} />
                    </Form.Group>
                  </>
                )}
                {!isEmpty(driversList) && (values.status === 'Delivered' || values.status === 'Out for Delivery' || values.status === 'Out for Return' || values.status === 'Returned') && (
                  <Form.Group>
                    <Form.Label>Driver</Form.Label>
                    <Form.Control className="text-capitalize" as="select" disabled={values?.status === shipment?.status} name="driver_id" custom value={values.driver_id} onChange={handleChange}>
                      <option value="-">-- Select --</option>
                      {driversList.map((driver, index) => {
                        return (
                          <option value={driver.id} key={index} className="text-capitalize">
                            {driver.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Description / Reason</Form.Label>
                  <Form.Control as="textarea" disabled={values?.status === shipment?.status} rows="5" name="description" value={values.description} onChange={handleChange} />
                </Form.Group>
                {(values.status === 'Delivered' || values.status === 'Out for Delivery') && (
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check disabled={values?.status === shipment?.status} type="checkbox" label="Notify Patient" checked={isNotify} onChange={(e) => setNotify(e.target.checked)} />
                  </Form.Group>
                )}
                {checkValidDate !== 'valid' && (
                  <>
                    <div className="text-danger">
                      <i className="fe fe-alert-circle" />
                      &nbsp;{checkValidDate}
                    </div>
                  </>
                )}
                <br />
                <Button disabled={values?.status === shipment?.status || loading || checkValidDate !== 'valid'} type="submit" variant="primary">
                  Update
                </Button>
                <Button
                  disabled={loading}
                  variant="outline-secondary"
                  onClick={() => {
                    showStatusModal(false);
                  }}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalHistory} onHide={() => showHistoryModal(false)}>
        <Modal.Body>
          <Modal.Title className="h1">Description</Modal.Title>
          <hr />
          <Formik initialValues={currentShipmentStatus} onSubmit={updateHistory}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control disabled as="select" name="status" custom value={values.status}>
                    <option value="Ready for Pickup">Ready for Pickup</option>
                    <option value="Out for Delivery">Out for Delivery</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Exception">Cancelled</option>
                    <option value="Out for Return">Out for Return</option>
                    <option value="Returned">Returned</option>
                  </Form.Control>
                </Form.Group>
                {isBatchShipment.length > 1 && (
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label={`Apply to Batch ${shipment?.batch_number}`} checked={batchEdit} onChange={(e) => setBatchEdit(e.target.checked)} />
                  </Form.Group>
                )}
                {!isEmpty(driversList) && (values.status === 'Delivered' || values.status === 'Out for Delivery' || values.status === 'Out for Return' || values.status === 'Returned') && (
                  <Form.Group>
                    <Form.Label>Driver</Form.Label>
                    <Form.Control as="select" name="driver_id" custom value={values.driver_id} onChange={handleChange}>
                      <option value="-">-- Select --</option>
                      {driversList.map((driver, index) => {
                        return (
                          <option value={driver.id} key={index}>
                            {driver.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Description / Reason</Form.Label>
                  <Form.Control as="textarea" rows="5" name="description" value={values.description} onChange={handleChange} />
                </Form.Group>
                <Button disabled={loading} type="submit" variant="primary">
                  Update
                </Button>
                <Button
                  disabled={loading}
                  variant="outline-secondary"
                  onClick={() => {
                    showHistoryModal(false);
                  }}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalTimeline} onHide={() => showTimelineModal(false)}>
        <Modal.Body>
          <Modal.Title className="h1">Timeline</Modal.Title>
          <hr />
          <Form.Group>
            <Form.Control as="select" name="status" custom value={timeLine} onChange={handleTimeLineChange}>
              <option value="Pickup Time">Pickup Time</option>
              <option value="Delivery Time">Delivery Time</option>
              <option value="Return Pickup Time">Return Pickup Time</option>
              <option value="Return Time">Return Time</option>
            </Form.Control>
          </Form.Group>
          {timeLine === 'Pickup Time' && (
            <Form.Group>
              <DatePicker name="pickupDate" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={pickupDate} onChange={(date) => handleDateChange(date, 'pickupDate')} />
            </Form.Group>
          )}

          {timeLine === 'Delivery Time' && (
            <Form.Group>
              <DatePicker name="deliveryDate" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={deliveryDate} onChange={(date) => handleDateChange(date, 'deliveryDate')} />
            </Form.Group>
          )}
          {timeLine === 'Return Pickup Time' && (
            <Form.Group>
              <DatePicker name="returnPickupDate" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={returnPickupDate} onChange={(date) => handleDateChange(date, 'returnPickupDate')} />
            </Form.Group>
          )}
          {timeLine === 'Return Time' && (
            <Form.Group>
              <DatePicker name="returnedDate" showTimeSelect className="form-control" dateFormat="MMM d, yyyy h:mm aa" selected={returnedDate} onChange={(date) => handleDateChange(date, 'returnedDate')} />
            </Form.Group>
          )}
          {checkValidDate !== 'valid' && (
            <>
              <div className="text-danger">
                <i className="fe fe-alert-circle" />
                &nbsp;{checkValidDate}
              </div>
            </>
          )}
          <br />
          <Button
            variant="primary"
            disabled={loading || checkValidDate !== 'valid'}
            onClick={() => {
              updateTimeline();
            }}
          >
            Update
          </Button>
          <Button
            disabled={loading}
            variant="outline-secondary"
            onClick={() => {
              showTimelineModal(false);
            }}
            className="ml-2"
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={podModal} onHide={() => showPodModal(false)} centered>
        <Modal.Body>
          <Modal.Title className="h1">Update Proof of delivery </Modal.Title>
          <hr />
          <div {...getRootProps({ className: 'dropzone dz-message py-6' })}>
            <input {...getInputProps()} />
            <p className='mb-0'>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <aside className='d-flex flex-row flex-wrap mt-3'>
            {files?.map((file, index) => (
              <div className='d-inline-flex rounded-2 border border-1 mb-1 ms-1 p-2 border-box  img-wrap' style={{ width: '100px', height: '100px' }} key={index}>
                <div className='d-flex' style={{ minWidth: 0, overflow: 'hidden' }}>
                  <span className="close" onClick={() => { removeImage(index) }}>&times;</span>
                  <img alt='product_image' src={
                    file.preview ? URL.createObjectURL(new Blob([file], { type: file.type })) : file
                  } className='d-block w-auto h-100'
                    onLoad={() => { URL.revokeObjectURL(file.preview ? file.preview : file) }} />
                </div>
              </div>
            ))}
          </aside>

          <div >
            {podContainer &&
              <div>
                <h2 >
                  <img alt='something' src={podContainer} width='300px' height='250px' />
                </h2>
              </div>
            }
          </div>
          <br />
          <Button
            variant="primary"
            disabled={files.length === 0}
            onClick={() => {
              handlePODUpdate()
            }}
          >
            Update
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              showPodModal(false);
              setFiles([])
            }}
            className="ml-2"
          >
            Cancel
          </Button>

        </Modal.Body>
      </Modal>

      <Modal size="lg" show={modalParcel} onHide={() => showParcelModal(false)}>
        <Modal.Body>
          <Modal.Title className="h1">View Parcel</Modal.Title>
          <hr />
          <div className="card mt-4">
            <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap card-table dataTable no-footer">
              <thead>
                <tr>
                  <th></th>
                  <th>Reference Number</th>
                  <th>
                    Product Type
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="list font-size-base">
                {orderList &&
                  orderList.map((data, index) => {
                    return (
                      <tr key={`${data}-${index}`}>
                        <td className="text-center">{`#${index + 1}`}</td>
                        <td>
                          <Form.Group className="mb-0">
                            <Form.Control key={index} id="index" type="text" name={`order_number-${index}`} placeholder="e.g. 1112-12322" value={data?.reference_number} disabled />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group className="mb-0">
                            <Form.Control key={index} id="index" type="text" name={`product_type-${index}`} value={data?.product_type} disabled></Form.Control>
                          </Form.Group>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="card-footer text-right">
            <Button disabled={loading} variant="outline-secondary" onClick={() => showParcelModal(false)}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={serviceModal} onHide={() => showServiceModal(false)}>
        <Modal.Body>
          <Modal.Title className="h1">Delivery Service</Modal.Title>
          <hr />
          <Formik initialValues={shipment} enableReinitialize onSubmit={handleServiceTypeUpdate}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control as="select" name="service_type"  value={values?.service_type} onChange={(e)=>handleChangeService(e.target.value)}  >
                    <option value="-">-- Select Service Type --</option>
                    {serviceTypeList.map((service, index) => {
                      return (
                        <option value={service.type} key={index}>
                          {service.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Button disabled={loading} type="submit" variant="primary">
                  Update
                </Button>
                <Button
                  disabled={loading}
                  variant="outline-secondary"
                  onClick={() => showServiceModal(false)}
                  className="ml-2" 
                   >
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return { user: state.user };
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(ShipmentDetail));

export const shipmentStatus = [
  { id: '01', name: 'Info Received' },
  { id: '02', name: 'Processing' },
  { id: '03', name: 'Ready for Pickup' },
  { id: '04', name: 'Out for Delivery' },
  { id: '03', name: 'Delivered' },
  { id: '04', name: 'Exception' },
];

export const deliveryInstructions = [
  { id: '01', name: 'Adult signature' },
  { id: '02', name: 'Refrigeration' },
  { id: '03', name: 'Special instruction' },
];

export const serviceTypeList = [
  { id: '01', key: 'same_day', value: 'Same Day' },
  { id: '02', key: 'next_day', value: 'Next Day' },
  { id: '03', key: 'monday', value: 'Monday' },
  { id: '04', key: 'future', value: 'Future' },
  { id: '05', key: 'GROUND_HOME_DELIVERY', value: 'Ground Home Delivery' },
  { id: '06', key: 'STANDARD_OVERNIGHT', value: 'Standard Overnight' }
];

export const mapStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

export const npsInitialValues = [
  { id: 0, colour: 'rgb(239,83,80)' },
  { id: 1, colour: 'rgb(239,83,80)' },
  { id: 2, colour: 'rgb(239,83,80)' },
  { id: 3, colour: 'rgb(239,83,80)' },
  { id: 4, colour: 'rgb(239,83,80)' },
  { id: 5, colour: 'rgb(239,83,80)' },
  { id: 6, colour: 'rgb(239,83,80)' },
  { id: 7, colour: 'rgb(255,167,38)' },
  { id: 8, colour: 'rgb(255,167,38)' },
  { id: 9, colour: 'rgb(124,179,66)' },
  { id: 10, colour: 'rgb(124,179,66)' },
];


export const npsInitialScores = [
  {
    id: '01',
    name: 'promoters',
    value: null,
    score: 0,
    color: 'rgb(124,179,66)',
  },
  {
    id: '02',
    name: 'passives',
    value: null,
    score: 0,
    color: 'rgb(255,167,38)',
  },
  {
    id: '03',
    name: 'detractors',
    value: null,
    score: 0,
    color: 'rgb(239,83,80)',
  },
];
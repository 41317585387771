/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import momentTz from 'moment-timezone';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Avatar from '../../components/Avatar';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from '../../components/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import { Line, Doughnut } from 'react-chartjs-2';
import Container from 'react-bootstrap/Container';
import CardTitle from '../../components/CardTitle';
import Pagination from 'react-bootstrap/Pagination';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getDashboardData } from '../../services/index';
import { npsInitialScores } from '../../constants/index';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { updateToken, toUtcDate, toLocalDate } from '../../Utils';

require('./RoundedBars');

const NPSDashboard = (props) => {

  const take = 5;
  const npsByShippersTake = 7;
  const timeZone = `${momentTz.tz.guess()}`;
  const [skip, setSkip] = useState(0);
  const [npsList, setNPSList] = useState([]);
  const [visible, setVisible] = useState(true);
  const [loading, showSpinner] = useState(false);
  const [npsByDay, setNpsByDay] = useState({});
  const [npsRating, setNpsRating] = useState(0);
  const [shipperId, setShipperId] = useState('');
  const [shipperList, setShipperList] = useState([]);
  const [npsByCategory, setNpsByCategory] = useState({});
  const [shipperFilter, setShipperFilter] = useState('');
  const [npsByshippers, setNpsByshippers] = useState([]);
  const [npsByDayOptions, setNpsByDayOptions] = useState({});
  const [npsScores, setNpsScores] = useState(npsInitialScores);
  const [npsByShippersSkip, setnpsByShippersSkip] = useState(0);
  const [dateFilters, setDateFilters] = useState({
    fromDate: `${moment().subtract(13, 'days').format('YYYY-MM-DD')}T00:00:00`,
    toDate: `${moment().format('YYYY-MM-DD')}T23:59:59`,
  });

  useEffect(() => {
    getShippers();
  }, []);

  useEffect(() => {
    getNpsByShippers();
  }, [npsByShippersSkip]);

  useEffect(() => {
    getNpsByCategory();
    getNPSByDay();
    getFeedback();
    getNpsByShippers();
  }, [shipperId, skip, dateFilters.fromDate, dateFilters.toDate]);

  const getShippers = async () => {
    const keywordFilter = '';
    const url = `shipper?keyword=${keywordFilter.toLowerCase().trim()}&take=${500}&skip=${0}`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shippers = response?.data;
        await setShipperList(shippers);
      } else {
        if (response?.response?.status === 401 || response.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getNpsByShippers = async () => {
    showSpinner(true);
    const url = `dashboard/feedback-nps-by-shipper?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${npsByShippersTake}&skip=${npsByShippersSkip}&status=delivered`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setNpsByshippers(response?.data);
        showSpinner(false);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getFeedback = async () => {
    showSpinner(true);
    const activeShipperFeedbacks = `shipment/feedback?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}&status=delivered`;
    const allFeedbacks = `shipment/feedback?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}&status=delivered`;
    const url = shipperFilter?.length > 0 ? activeShipperFeedbacks : allFeedbacks;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let npsData = response?.data.filter((item) => item?.nps !== '');
        npsData = npsData.map((item) => {
          item.color = Number(item.nps) <= 6 ? 'rgb(239,83,80)' : Number(item.nps) >= 7 && Number(item.nps) <= 8 ? 'rgb(255,167,38)' : Number(item.nps) >= 9 && Number(item.nps) <= 10 ? 'rgb(124,179,66)' : 'none';
          item.border = Number(item.nps) <= 6 ? '7px solid rgb(239,83,80)' : Number(item.nps) >= 7 && Number(item.nps) <= 8 ? '7px solid rgb(255,167,38)' : Number(item.nps) >= 9 && Number(item.nps) <= 10 ? '7px solid rgb(124,179,66)' : 'none';
          return item;
        });
        setNPSList(npsData);
        showSpinner(false);
      } else {
        if (response?.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getNPSByDay = async () => {
    const allNpsByDay = `dashboard/feedback-nps-by-day?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&time_zone=${timeZone}&status=delivered`;
    const activeNpsByDay = `dashboard/feedback-nps-by-day?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&time_zone=${timeZone}&status=delivered`;
    const url = shipperFilter?.length > 0 ? activeNpsByDay : allNpsByDay;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let labels = [];
        const npsData = Object.entries(response?.data)
          .sort()
          .reduce((o, [k, v]) => ((o[k] = v), o), {});
        for (let date of Object.keys(npsData)) {
          labels.push(toLocalDate(date, 'll'));
        }
        const dailyNps = await Object.entries(npsData)
          .map(([key, value]) => ({ [key]: value }))
          .map((nps) => {
            for (let x in nps) {
              if (labels.includes(x)) {
                labels[labels.indexOf(x)] = {
                  date: x,
                  npsScore: Object.values(nps)[0],
                };
              }
              return {
                date: x,
                npsScore: Object.values(nps)[0],
              };
            }

            return null;
          });
        const data = {
          labels: labels,
          datasets: [
            {
              label: 'NPS',
              lineTension: 0.1,
              pointRadius: 5,
              promoters: dailyNps.map((nps) => `${Math.round((Number(nps?.npsScore?.promoters) / Number(nps?.npsScore?.total)) * 100)}% promoters (${nps?.npsScore?.promoters} total)`),
              passives: dailyNps.map((nps) => `${Math.round((Number(nps?.npsScore?.passives) / Number(nps?.npsScore?.total)) * 100)}% passives (${nps?.npsScore?.passives} total)`),
              detractors: dailyNps.map((nps) => `${Math.round((Number(nps?.npsScore?.detractors) / Number(nps?.npsScore?.total)) * 100)}% detractors (${nps?.npsScore?.detractors} total)`),
              totalResponses: dailyNps.map((nps) => Number(nps.npsScore.total)),
              data: dailyNps.map((nps) => Math.round(((Number(nps.npsScore.promoters) - Number(nps.npsScore.detractors)) / Number(nps.npsScore.total)) * 100)),
              backgroundColor: '#2c7be5',
              fill: false,
              borderColor: '#2c7be5',
            },
          ],
        };
        const options = {
          responsive: true,
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: function (tooltipItems, data) {
                let multistringText = [tooltipItems.yLabel];
                multistringText.push(`${data.datasets[0].totalResponses[tooltipItems.index]} responses`);
                multistringText.push(data.datasets[0].promoters[tooltipItems.index]);
                multistringText.push(data.datasets[0].passives[tooltipItems.index]);
                multistringText.push(data.datasets[0].detractors[tooltipItems.index]);
                return multistringText;
              },
            },
          },
          spanGaps: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: -100,
                  max: 100,
                },
              },
            ],
          },
        };
        setNpsByDay(data);
        setNpsByDayOptions(options);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getNpsByCategory = async () => {
    const allNpsByCategory = `/dashboard/feedback-nps-by-category?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&status=delivered`;
    const activeNpsByCategory = `/dashboard/feedback-nps-by-category?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&status=delivered`;
    const url = shipperFilter?.length > 0 ? activeNpsByCategory : allNpsByCategory;
    const response = await getDashboardData(url);
    const npsCategory = { ...response.data };
    const npsData = response?.data;
    delete npsData.total;
    const promotersPercentage = Math.round((Number(npsCategory?.promoters) / Number(npsCategory?.total)) * 100);
    const passivesPercentage = Math.round((Number(npsCategory?.passives) / Number(npsCategory?.total)) * 100);
    const detractorsPercentage = Math.round((Number(npsCategory?.detractors) / Number(npsCategory?.total)) * 100);
    const nps = Math.round(((Number(npsCategory.promoters) - Number(npsCategory.detractors)) / Number(npsCategory.total)) * 100);
    const labels = [];
    const npsScores = npsInitialScores.map((data) => {
      data.score = data.name === 'promoters' ? promotersPercentage : data.name === 'passives' ? passivesPercentage : data.name === 'detractors' ? detractorsPercentage : 0;
      data.value = data.name === 'promoters' ? `${isNaN(promotersPercentage) ? 0 : promotersPercentage}% (${npsData.promoters} Promoters)` : data.name === 'passives' ? `${isNaN(passivesPercentage) ? 0 : passivesPercentage}% (${npsData.passives} Passives)` : data.name === 'detractors' ? `${isNaN(detractorsPercentage) ? 0 : detractorsPercentage}% (${npsData.detractors} Detractors)` : 0;
      return data;
    });
    if (Number(npsCategory?.total) === 0) {
      setVisible(false);
    } else {
      setVisible(true);
    }
    await setNpsScores(npsScores);
    Object.keys(npsData).forEach((item) => labels.push(item === 'promoters' ? `${item}  (${isNaN(promotersPercentage) ? 0 : promotersPercentage}%)` : item === 'passives' ? `${item}  (${isNaN(passivesPercentage) ? 0 : passivesPercentage}%)` : item === 'detractors' ? `${item}  (${isNaN(detractorsPercentage) ? 0 : detractorsPercentage}%)` : item));
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const data = {
          labels: labels,
          datasets: [
            {
              data: Object.values(npsData),
              backgroundColor: ['rgb(124,179,66)', 'rgb(255,167,38)', 'rgb(239,83,80)'],
            },
          ],
        };
        setNpsRating(isNaN(nps) ? 0 : nps);
        setNpsByCategory(data);
      } else {
        if (response?.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const handlePrevious = (name) => {
    if (name === 'nps') {
      setnpsByShippersSkip(npsByShippersSkip - npsByShippersTake);
    } else {
      setSkip(skip - take);
    }
  };

  const handleNext = (name) => {
    if (name === 'nps') {
      setnpsByShippersSkip(npsByShippersSkip + npsByShippersTake);
    } else {
      setSkip(skip + take);
    }
  };

  const handleDateApplied = (event, picker) => {
    const fromDate = `${moment(picker.startDate).format('YYYY-MM-DD')}T00:00:00`;
    const toDate = `${moment(picker.endDate).format('YYYY-MM-DD')}T23:59:59`;
    setDateFilters({ fromDate, toDate });
  };

  return (
    <>
      <Navbar />
      <div className='main-content'>
        <Header title='Phox Health' name='Nps Overview'>
          <div className='ml-1'>
            <Dropdown>
              <Dropdown.Toggle variant='white'>Shipper{shipperFilter && <span>: {shipperFilter}</span>}</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShipperFilter('');
                    setShipperId('');
                  }}
                >
                  All
                </Dropdown.Item>
                {shipperList &&
                  shipperList.map((shipper, i) => {
                    return (
                      <>
                        <Dropdown.Item
                          key={i}
                          onClick={() => {
                            setShipperFilter(shipper.name);
                            setShipperId(shipper.id);
                          }}
                        >
                          {shipper.name}
                        </Dropdown.Item>
                      </>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Header>
        <Container className='mt-5'>
          <Card>
            <Card.Header>
              <Row>
                <Col sm='auto'>
                  <Row>
                    <Col>
                      <DateRangePicker
                        initialSettings={{
                          startDate: moment().subtract(13, 'days'),
                          endDate: moment(),
                          linkedCalendars: true,
                          showCustomRangeLabel: true,
                          showDropdowns: true,
                          alwaysShowCalendars: true,
                          opens: 'left',
                          ranges: {
                            Today: [moment(), moment()],
                            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                          },
                        }}
                        onApply={handleDateApplied}
                      >
                        <input className='btn btn-light' style={{ cursor: 'pointer' }} />
                      </DateRangePicker>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>
          </Card>
          {visible && (
            <Card className='mt-5'>
              <ProgressBar>
                {npsScores.map((category, index) => (
                  <OverlayTrigger
                    key={index}
                    placement={'top'}
                    overlay={
                      <Tooltip id={index}>
                        <strong>{category?.value}</strong>.
                      </Tooltip>
                    }
                  >
                    <ProgressBar isChild={true} now={category?.score} key={index} style={{ backgroundColor: category.color }} />
                  </OverlayTrigger>
                ))}
              </ProgressBar>
            </Card>
          )}

          <Spinner display={loading}>
            <Row>
              <Col lg='12'>
                <Card>
                  <CardTitle title='NPS over time'></CardTitle>
                  <Card.Body>
                    <Line height={100} data={npsByDay} options={npsByDayOptions} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg='4'>
                <Card>
                  <CardTitle title={`Net Promoter Score - ${npsRating}`}></CardTitle>
                  <Card.Body>
                    <Doughnut data={npsByCategory} width={110} />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg='8'>
                <Card>
                  <Spinner display={loading}>
                    <Table responsive size='sm'>
                      <thead>
                        <tr>
                          <th>Shipper</th>
                          <th>NPS</th>
                        </tr>
                      </thead>
                      {npsByshippers &&
                        npsByshippers.map((npsCategory, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td>{npsCategory?.shipper}</td>
                                <td>{Math.round(((Number(npsCategory.promoters) - Number(npsCategory.detractors)) / Number(npsCategory.total)) * 100)}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </Table>
                    <Card.Footer>
                      <Pagination size='sm' className='justify-content-center'>
                        <Pagination.Item
                          disabled={npsByShippersSkip === 0}
                          onClick={() => {
                            handlePrevious('nps');
                          }}
                        >
                          Previous
                        </Pagination.Item>
                        <Pagination.Item disabled={true}>Page {npsByShippersSkip / npsByShippersTake + 1}</Pagination.Item>
                        <Pagination.Item
                          disabled={npsByshippers?.length < npsByShippersTake}
                          onClick={() => {
                            handleNext('nps');
                          }}
                        >
                          Next
                        </Pagination.Item>
                      </Pagination>
                    </Card.Footer>
                  </Spinner>
                </Card>
              </Col>
            </Row>
            {npsList.map((feedback, index) => {
              return (
                <Card key={index}>
                  <Card.Body>
                    <Row className='mb-3'>
                      <Col>
                        <Container>
                          <Row>
                            <Col sm='auto' className='pr-0'>
                              <Avatar />
                            </Col>
                            <Col>
                              <h4 className='mb-1'>{feedback.user_name}</h4>
                              <p className='small text-muted mb-0'>{moment(moment(feedback.create_date).local()).startOf('hour').fromNow()}</p>
                            </Col>
                          </Row>
                          <Row>
                            <p className='mt-3 pl-3'>{feedback.comment}</p>
                          </Row>
                          <Row>
                            <div className='pl-3'>
                              <Link className='btn btn-sm btn-primary' to={`/shipment/${feedback.shipment_id}`}>
                                Shipment #{feedback.number}
                              </Link>
                            </div>
                          </Row>
                        </Container>
                      </Col>
                      <Col sm='auto'>
                        <div className='avatar avatar-lg d-block' style={{ marginLeft: '40px' }}>
                          <h1 className='avatar-title rounded-circle bg-transparent' style={{ color: feedback.color, border: feedback.border }}>
                            {Number(feedback.nps)}
                          </h1>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
            <Card.Footer>
              <Pagination size='sm' className='justify-content-center'>
                <Pagination.Item
                  disabled={skip === 0}
                  onClick={() => {
                    handlePrevious('feedback');
                  }}
                >
                  Previous
                </Pagination.Item>
                <Pagination.Item disabled={true}>Page {skip / take + 1}</Pagination.Item>
                <Pagination.Item
                  disabled={npsList.length + 1 < take}
                  onClick={() => {
                    handleNext('feedback');
                  }}
                >
                  Next
                </Pagination.Item>
              </Pagination>
            </Card.Footer>
          </Spinner>
        </Container>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(NPSDashboard);

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import ShipmentList from './ShipmentList';
import Navbar from '../../components/Navigation';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import TodaysDeliveries from './TodaysDeliveries';
import { updateToken, toUtcDate } from '../../Utils';
import { getShipmentData } from '../../services/index';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const Shipments = (props) => {
  const [active, setActive] = useState('today');
  const [loading, showSpinner] = useState(false);
  const [totalShipmentCount, setShipmentCount] = useState(0);
  const [todaysDeliveries, setTodaysDeliveries] = useState({});
  const [dateFilters, setDateFilters] = useState({
    fromDate: `${moment().subtract(13, 'days').format('YYYY-MM-DD')}T00:00:00`,
    toDate: `${moment().format('YYYY-MM-DD')}T23:59:59`,
  });

  useEffect(() => {
    getShipmentsCount();
    getDeliveriesCount();
    document.title = 'Shipments';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const getShipmentsCount = async (shipper, filters, keywordFilter) => {
    showSpinner(true);
    const groupName = filters?.groupsFilter ? filters?.groupsFilter?.toLowerCase() : '';
    const activeShipper = shipper?.length ? shipper : '';
    const activeStatus = filters?.statusFilter ? filters?.statusFilter?.toLowerCase() : '';
    const keyword = keywordFilter?.length > 0 ? encodeURI(keywordFilter?.trim()) : '';
    let url = '';
    if (filters?.instructionsFilter === 'Refrigeration') {
      url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&refrigeration_required=true&keyword=${keyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    } else if (filters?.instructionsFilter === 'Adult signature') {
      url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&signature_required=true&keyword=${keyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    } else if (filters?.instructionsFilter === 'Special instruction') {
      url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&special_instruction=true&keyword=${keyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    } else {
      if (keyword?.length > 0) {
        url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&keyword=${keyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
      } else {
        url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&keyword=${keyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
      }
    }
    const response = await getShipmentData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setShipmentCount(Object.values(response?.data).reduce((totalCount, count) => Number(totalCount) + Number(count), 0));
        showSpinner(false);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const getDeliveriesCount = async (keywordFilter, statusFilter) => {
    showSpinner(true);
    const keyword = keywordFilter?.length ? keywordFilter?.toLowerCase().trim() : '';
    const status = statusFilter?.length ? statusFilter?.toLowerCase().trim() : '';
    const url = `dashboard/shipment-today-count?keyword=${keyword}&status=${status}&yesterday_from_date=${toUtcDate(`${moment().subtract(1, 'day').format('YYYY-MM-DD')}T00:00:00`)}&yesterday_to_date=${toUtcDate(`${moment().subtract(1, 'day').format('YYYY-MM-DD')}T23:59:59`)}&today_from_date=${toUtcDate(`${moment().format('YYYY-MM-DD')}T00:00:00`)}&today_to_date=${toUtcDate(`${moment().format('YYYY-MM-DD')}T23:59:59`)}`;
    const response = await getShipmentData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setTodaysDeliveries(response?.data);
        showSpinner(false);
      }
    } else {
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
      showSpinner(false);
    }
  };

  const handleDateApplied = (event, picker) => {
    const fromDate = `${moment(picker.startDate).format('YYYY-MM-DD')}T00:00:00`;
    const toDate = `${moment(picker.endDate).format('YYYY-MM-DD')}T23:59:59`;
    setDateFilters({ fromDate, toDate });
  };

  return (
    <>
      <Navbar />
      <div className="main-content">
        <Header
          title="Phox Health"
          name={
            <>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <div className={active === `today` ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive(`today`)}>
                    <h1 className="header-title">
                      Today{' '}
                      <span className="badge badge-pill badge-soft-secondary" style={{ fontSize: '60%' }}>
                        {isEmpty(todaysDeliveries) ? 0 : todaysDeliveries?.total}
                      </span>
                    </h1>
                  </div>
                </li>
                <li className="nav-item">
                  <div className={active === 'all' ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => setActive('all')}>
                    <h1 className="header-title">
                      All{' '}
                      <span className="badge badge-pill badge-soft-secondary" style={{ fontSize: '60%' }}>
                        {totalShipmentCount}
                      </span>
                    </h1>
                  </div>
                </li>
              </ul>
            </>
          }
        >
          {active !== `today` && (
            <Col className="p-1">
              <DateRangePicker
                initialSettings={{
                  startDate: moment().subtract(13, 'days'),
                  endDate: moment(),
                  linkedCalendars: true,
                  showCustomRangeLabel: true,
                  showDropdowns: true,
                  alwaysShowCalendars: true,
                  opens: 'left',
                  ranges: {
                    All: [moment('08/01/2019'), moment()],
                    Today: [moment(), moment()],
                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                  },
                }}
                onApply={handleDateApplied}
              >
                <input className="btn btn-white mt-4" style={{ cursor: 'pointer' }} />
              </DateRangePicker>
            </Col>
          )}
        </Header>
        <Spinner display={loading}>{active === `today` ? <TodaysDeliveries deliveries={todaysDeliveries} parentCallback={getDeliveriesCount} /> : <ShipmentList dateFilters={dateFilters} parentCallback={getShipmentsCount} />}</Spinner>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Shipments);

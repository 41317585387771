/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from '../../components/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from 'react-bootstrap/Tooltip';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { deliveryInstructions } from '../../constants/index';
import { updateToken, toTimeZoneDate, toTimeZone, toUtcDate, getTAT } from '../../Utils';
import { getShipmentData, getAllGroups, getShippersByGroup, getDashboardData, shipmentStatusList } from '../../services/index';

const reportsHelper = require('../../helpers/appHelper');

const ShipmentList = (props) => {
  const defaultFilters = {
    groupsFilter: '',
    shipperFilter: '',
    selectedShipper: '',
    statusFilter: '',
    instructionsFilter: '',
  };

  const { dateFilters } = props;
  const take = 25;
  const timeoutRef = useRef(null);
  const [skip, setSkip] = useState(0);
  const [groups, setGroups] = useState([]);
  const [reset, setReset] = useState(false);
  const [timeout, updateTimeout] = useState(0);
  const [visible, setVisible] = useState(true);
  const [loading, showSpinner] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [keywordFilter, setKeyword] = useState('');
  const [shipperList, setShipperList] = useState([]);
  const [shipperIdList, setShipperIDList] = useState([]);
  const [modalFilter, showFilterModal] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [shipmentStatus, setShipmentStatus] = useState([]);
  const [shipmentsCounts, setShipmentsCounts] = useState([]);
  const [shipmentByStatus, setShipmentByStatus] = useState({});

  useEffect(() => {
    getShipmentStatusList();
    getGroups();
  }, []);

  useEffect(() => {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getShippers();
    }, timeout);
  }, [keywordFilter, skip, dateFilters.fromDate, dateFilters.toDate]);

  useEffect(() => {
    getActiveShippers();
  }, [filters?.groupsFilter]);

  useEffect(() => {
    if (reset === true) {
      getShippers();
    }
  }, [reset]);

  useEffect(() => {
    setPagination();
  }, [dateFilters.fromDate, dateFilters.toDate]);

  useEffect(() => {
    resetShippers();
  }, [filters?.groupsFilter]);

  const getActiveShippers = async () => {
    const keywordFilter = '';
    const url = `shipper?keyword=${keywordFilter?.toLowerCase().trim()}&take=${500}&skip=${0}`;
    const response = filters?.groupsFilter && filters?.groupsFilter.length > 0 ? await getShippersByGroup(filters?.groupsFilter?.toLowerCase()) : await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shippers = response?.data;
        await setShipperList(shippers);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShipmentStatusList = async () => {
    const response = await shipmentStatusList();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const statusList = response?.data?.filter((item) => item !== 'Info Received' && item !== 'Processing');
        const shipmentStatus = statusList?.map((item, index) => {
          return { id: index, name: item };
        });
        setShipmentStatus(shipmentStatus);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShipments = async (shipperId) => {
    showSpinner(true);
    const shipper = filters?.shipperFilter?.length > 0 && filters?.selectedShipper?.length > 0 ? filters?.selectedShipper : shipperId?.length > 0 ? shipperId : '';
    let url = '';

    const searchKeyword = keywordFilter?.length > 0 ? encodeURI(keywordFilter?.trim()) : '';
    if (filters?.instructionsFilter === 'Refrigeration') {
      url = `shipment?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&refrigeration_required=true&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
    } else if (filters?.instructionsFilter === 'Adult signature') {
      url = `shipment?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&signature_required=true&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
    } else if (filters?.instructionsFilter === 'Special instruction') {
      url = `shipment?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&special_instruction=true&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
    } else {
      if (keywordFilter?.length > 0) {
        url = `shipment?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
      } else {
        url = `shipment?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
      }
    }

    props.parentCallback(shipper, filters, keywordFilter);
    getShipmentsByStatus(shipper, filters, keywordFilter);
    const response = await getShipmentData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shipmentsList = response?.data;
        setShipments(shipmentsList);
        showSpinner(false);
        showFilterModal(false);
        updateTimeout(1500);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
        showFilterModal(false);
      }
    }
  };

  const getReportData = async (count, shipperId) => {
    const shipper = filters?.shipperFilter?.length > 0 && filters?.selectedShipper?.length > 0 ? filters?.selectedShipper : shipperId?.length > 0 ? shipperId : '';
    let url = '';

    const searchKeyword = keywordFilter?.length > 0 ? encodeURI(keywordFilter?.trim()) : '';
    if (filters?.instructionsFilter === 'Refrigeration') {
      url = `dashboard/shipment-feedback?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&keyword=${searchKeyword}&refrigeration_required=true&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${count}&skip=0`;
    } else if (filters?.instructionsFilter === 'Adult signature') {
      url = `dashboard/shipment-feedback?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&keyword=${searchKeyword}&signature_required=true&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${count}&skip=0`;
    } else if (filters?.instructionsFilter === 'Special instruction') {
      url = `dashboard/shipment-feedback?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&keyword=${searchKeyword}&special_instruction=true&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${count}&skip=0`;
    } else {
      if (keywordFilter?.length > 0) {
        url = `dashboard/shipment-feedback?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${count}&skip=0`;
      } else {
        url = `dashboard/shipment-feedback?group_name=${filters?.groupsFilter?.toLowerCase()}&shipper=${shipper}&status=${filters?.statusFilter?.toLowerCase()}&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${count}&skip=0`;
      }
    }

    const response = await getShipmentData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let reportData = response?.data.map((shipment) => {
          let deliveryProof = '',
            deliveryMessage = '';
          let isVerifiedWithOtp = false;
          const verificationCode = shipment?.verification_code;
          const isDelivered = shipment?.status === 'Delivered';
          if (isDelivered) {
            const message = shipment?.description;
            if (Array.isArray(shipment?.attachments) && shipment?.attachments?.length > 0) {
              let data = '';
              if (shipment?.attachments[0]?.includes('{"url"')) {
                data = JSON.parse(shipment?.attachments[0]);
                data = Object.values(data);
                deliveryProof = data && data[0];
              } else {
                data = shipment?.attachments[0];
                deliveryProof = data;
              }
              isVerifiedWithOtp = false;
            } else if (Number(message) === verificationCode) {
              deliveryMessage = `Verified with OTP - ${verificationCode}`;
              isVerifiedWithOtp = true;
            } else {
              deliveryMessage = message;
              isVerifiedWithOtp = false;
            }
          }

          const turnAroundTime = shipment.delivery_date && shipment.create_date ? `${getTAT(toTimeZone(shipment?.delivery_date, 'lll', shipment?.ship_from?.timezone), toTimeZone(shipment?.create_date, 'lll', shipment?.ship_from?.timezone))}` : '';
          shipment.TAT = turnAroundTime >= 0 ? Number(turnAroundTime) : null;
          shipment.delivery_proof = isVerifiedWithOtp ? (deliveryMessage?.length > 0 ? deliveryMessage : '') : deliveryProof?.length > 0 ? deliveryProof : deliveryMessage?.length > 0 ? deliveryMessage : '';
          shipment.parcel_count=Number(shipment?.parcels && shipment?.parcels.length > 0 && Object.values(shipment?.parcels[0])[0]?.length)||0;
          shipment.nps = shipment?.nps?.length > 0 ? Number(shipment?.nps) : null;
          shipment.rating = shipment?.rating >= 0 ? shipment?.rating : null;
          shipment.distance= shipment?.distance ? Number(shipment?.distance) : 0;
          shipment.comment = shipment?.comment?.length > 0 ? shipment?.comment : '';
          shipment.create_date = toTimeZone(shipment?.create_date, 'MMM D YYYY, h:mm a', shipment?.ship_from?.timezone);
          shipment.pickup_date = shipment?.pickup_date ? toTimeZone(shipment?.pickup_date, 'MMM D YYYY, h:mm a', shipment?.ship_from?.timezone) : '';
          shipment.delivery_date = shipment?.delivery_date ? toTimeZone(shipment?.delivery_date, 'MMM D YYYY, h:mm a', shipment?.ship_from?.timezone) : '';
          shipment.out_for_return_date = shipment?.out_for_return_date ? toTimeZone(shipment?.out_for_return_date, 'MMM D YYYY, h:mm a', shipment?.ship_from?.timezone) : '';
          shipment.returned_date = shipment?.returned_date ? toTimeZone(shipment?.returned_date, 'MMM D YYYY, h:mm a', shipment?.ship_from?.timezone) : '';
          return shipment;
        });
        reportData = reportData.sort((a, b) => b.number - a.number);
        return reportData;
      }
    } else {
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
    }
  };

  const getShipmentsByStatus = async (shipper, filters, keywordFilter) => {
    const groupName = filters?.groupsFilter ? filters?.groupsFilter?.toLowerCase() : '';
    const activeShipper = shipper?.length ? shipper : '';
    const activeStatus = filters?.statusFilter ? filters?.statusFilter?.toLowerCase() : '';
    const searchKeyword = keywordFilter?.length > 0 ? encodeURI(keywordFilter?.trim()) : '';
    let url = '';
    if (filters?.instructionsFilter === 'Refrigeration') {
      url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&refrigeration_required=true&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    } else if (filters?.instructionsFilter === 'Adult signature') {
      url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&signature_required=true&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    } else if (filters?.instructionsFilter === 'Special instruction') {
      url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&special_instruction=true&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    } else {
      if (searchKeyword?.length > 0) {
        url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
      } else {
        url = `dashboard/shipment-by-status?group_name=${groupName}&shipper=${activeShipper}&status=${activeStatus}&keyword=${searchKeyword}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
      }
    }
    const response = await getShipmentData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setShipmentByStatus(response?.data);
        const shipmentsCounts = [
          {
            value: 'Ready For Pickup',
            color: '#2C7BE5',
            count: Number(response?.data['Ready for Pickup']),
          },
          {
            value: 'Out For Delivery',
            color: '#E8C50D',
            count: Number(response?.data['Out for Delivery']),
          },
          {
            value: 'Delivered',
            color: '#34A443',
            count: Number(response?.data['Delivered']),
          },
          {
            value: 'Cancelled',
            color: '#E63757',
            count: Number(response?.data['Exception']),
          },
          {
            value: 'Out for Return',
            color: '#FB8137',
            count: Number(response?.data['Out for Return']),
          },
          {
            value: 'Returned',
            color: '#885AEA',
            count: Number(response?.data['Returned']),
          },
        ];
        setShipmentsCounts(shipmentsCounts);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getTotalShipmentsCounts = async () => {
    const url = 'dashboard/shipment-by-status';
    const response = await getShipmentData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shipmentCount = Object.values(response?.data).reduce((totalCount, count) => Number(totalCount) + Number(count), 0);
        const reports = await getReportData(shipmentCount, shipperIdList);
        return reports;
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getGroups = async () => {
    const response = await getAllGroups();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const data = response?.data;
        let group = data.map((group, index) => ({
          id: index + 1,
          name: group,
        }));
        group = [...new Set(group)];
        setGroups(group);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShippers = async () => {
    const keywordFilter = '';
    const url = `shipper?keyword=${keywordFilter?.toLowerCase().trim()}&take=${500}&skip=${0}`;
    const response = filters?.groupsFilter && filters?.groupsFilter.length > 0 ? await getShippersByGroup(filters?.groupsFilter?.toLowerCase()) : await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shippers = response?.data;
        let ShipperIds = [];
        await setShipperList(shippers);
        if (filters?.groupsFilter?.length > 0 && shippers?.length <= 0) {
          setVisible(false);
        } else if (filters?.groupsFilter?.length > 0 && shippers?.length > 0) {
          ShipperIds = shippers.map((shipper) => {
            return shipper.id;
          });
          setVisible(true);
        }
        await getShipments(ShipperIds);
        await setShipperIDList(ShipperIds);
        //getTotalShipmentsCounts(ShipperIds);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const generateReport = async (name) => {
    if (name === 'xls') {
      const reports = await getTotalShipmentsCounts();
      if (reports?.length > 0) {
        await reportsHelper.downloadShipmentExcelReport(reports, dateFilters);
      } else {
        toast.error('Sorry no data or something went wrong please try again');
      }
    } else if (name === 'csv') {
      const reports = await getTotalShipmentsCounts();
      if (reports?.length > 0) {
        await reportsHelper.downloadShipmentCsvReport(reports, dateFilters);
      } else {
        toast.error('Sorry no data or something went wrong please try again');
      }
    }
  };

  const resetShippers = () => {
    setFilters({ ...filters, shipperFilter: '', selectedShipper: '' });
    if (filters?.groupsFilter?.length < 0) {
      setVisible(true);
    }
  };

  const setPagination = () => {
    if (shipments?.length <= 25) {
      setSkip(0);
    }
  };

  const onSearch = ({ target: { value } }) => {
    setSkip(0);
    setKeyword(value);
  };

  const onRefresh = async () => {
    setReset(true);
    setKeyword('');
    setFilters(defaultFilters);
    setTimeout(() => {
      setReset(false);
    }, 1000);
  };

  const onFiltersChange = (name, value) => {
    setSkip(0);
    if (name === 'group') {
      setFilters({ ...filters, groupsFilter: value?.name || '' });
    } else if (name === 'shipper') {
      setFilters({ ...filters, shipperFilter: value?.name || '', selectedShipper: value?.id });
    } else if (name === 'status') {
      setFilters({ ...filters, statusFilter: value?.name || '' });
    } else if (name === 'instructions') {
      setFilters({ ...filters, instructionsFilter: value?.name || '' });
    }
  };

  const handlePrevious = () => {
    setSkip(skip - take);
  };

  const onApplyFilter=()=>{
    const page=skip / take + 1 ;
    if (page !== 1) {
      setSkip(0);
    }
    getShippers();
  }

  const handleNext = () => {
    setSkip(skip + take);
  };

  const isReset = filters?.groupsFilter?.length <= 0 && filters?.shipperFilter?.length <= 0 && filters?.statusFilter?.length <= 0 && filters?.instructionsFilter?.length <= 0;
  const totalShipments = shipmentsCounts?.reduce((count, shipment) => count + shipment.count, 0);

  return (
    <>
      <Container>
        <div>
          <Row className="d-flex justify-content-between">
            <Col>
              <span className="progress_status progress_status_ready">Ready for Pickup {shipmentByStatus['Ready for Pickup']}</span>
            </Col>
            <Col className="text-center">
              <span className="progress_status progress_status_out">Out for Delivery {shipmentByStatus['Out for Delivery']}</span>
            </Col>
            <Col>
              <span className="progress_status progress_status_delivered float-right">Delivered {shipmentByStatus['Delivered']}</span>
            </Col>
          </Row>
          <ProgressBar style={{ height: '0.6rem' }} className="mb-4">
            {shipmentsCounts.map((category, index) => (
              <OverlayTrigger
                key={index}
                placement={'top'}
                overlay={
                  <Tooltip id={index}>
                    <strong>{category?.count > 0 ? `${category?.value} ${category?.count}` : null}</strong>
                  </Tooltip>
                }
              >
                <ProgressBar isChild={true} max={totalShipments} now={category?.count} key={index} style={{ backgroundColor: category?.count > 0 ? category?.color : 'transparent' }} />
              </OverlayTrigger>
            ))}
          </ProgressBar>
        </div>
        <Card>
          <Card.Header>
            <Row>
              <Col>
                <InputGroup className="input-group-flush">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="fe fe-search"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl placeholder="eg: shipment number, sender, recipient, delivery instructions, reference number or order number" value={keywordFilter} onChange={onSearch} />
                </InputGroup>
              </Col>
              <Col sm="auto">
                <Button variant="white" onClick={() => showFilterModal(true)}>
                  Filter <i className="fe fe-sliders ml-2"></i>
                </Button>
              </Col>
              <Col sm="auto">
                <Dropdown>
                  <Dropdown.Toggle variant="white">Export</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        generateReport('xls');
                      }}
                    >
                      Xls
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        generateReport('csv');
                      }}
                    >
                      Csv
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm="auto" className="p-0">
                <Button variant="white" onClick={onRefresh}>
                  <i className="fe fe-refresh-cw"></i>
                </Button>
              </Col>
            </Row>
          </Card.Header>

          <Spinner display={loading}>
            <Table className="table-hover table-borderless table-thead-bordered" responsive size="sm">
              <thead>
                <tr>
                  <th>Shipment</th>
                  <th>Sender</th>
                  <th>Recipient</th>
                  <th>Courier</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {shipments &&
                  shipments.map((shipment, index) => {
                    return (
                      <tr style={{ cursor: 'pointer' }} onClick={() => window.open(`shipment/${shipment.id}`,"_blank")}>
                        <td>
                          {shipment.number}
                          {shipment?.batch_number > 0 && <div className="small text-muted">{`Batch - ${shipment.batch_number}`}</div>}
                        </td>
                        <td>
                          {shipment.ship_from.shipper_name}
                          <div className="small text-muted">
                            {shipment.ship_from.city}, {shipment.ship_from.state}
                          </div>
                        </td>
                        <td>
                          {shipment.ship_to.contact_name}
                          <div className="small text-muted">
                            {shipment.ship_to.city}, {shipment.ship_to.state}
                          </div>
                        </td>
                        <td>
                          {shipment.carrier.name}
                          {shipment?.special_instruction?.length > 0 && (
                            <span className="text-warning ml-2" data-toggle="tooltip" data-placement="top" title={shipment?.special_instruction}>
                              <i className="fe fe-alert-triangle"></i>
                            </span>
                          )}
                        </td>
                        <td>
                          {shipment?.status_updated_date && toTimeZoneDate(shipment.status_updated_date, 'LT', shipment?.ship_from?.timezone)}
                          <div className="small text-muted">{shipment?.status_updated_date && toTimeZone(shipment.status_updated_date, 'll', shipment?.ship_from?.timezone)}</div>
                        </td>
                        <td>
                          {shipment.status === 'In Transit' && <span className="h3 text-warning mr-2">●</span>}
                          {shipment.status === 'Info Received' && <span className="h3 text-primary mr-2">●</span>}
                          {shipment.status === 'Processing' && <span className="h3 text-primary mr-2">●</span>}
                          {shipment.status === 'Ready for Pickup' && <span className="h3 text-primary mr-2">●</span>}
                          {shipment.status === 'Out for Delivery' && <span className="h3 text-warning mr-2">●</span>}
                          {shipment.status === 'Delivered' && <span className="h3 text-success mr-2">●</span>}
                          {shipment.status === 'Exception' && <span className="h3 text-danger mr-2">●</span>}
                          {shipment.status === 'Out for Return' && (
                            <span className="h3 mr-2" style={{ color: 'rgb(251,129,55)' }}>
                              ●
                            </span>
                          )}
                          {shipment.status === 'Returned' && (
                            <span className="h3 mr-2" style={{ color: 'rgb(136,90,234)' }}>
                              ●
                            </span>
                          )}
                          {shipment.status === 'Exception' ? 'Cancelled' : shipment.status}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Pagination size="sm" className="justify-content-center">
              <Pagination.Item
                disabled={skip === 0}
                onClick={() => {
                  handlePrevious();
                }}
              >
                Previous
              </Pagination.Item>
              <Pagination.Item disabled={true}>Page {skip / take + 1}</Pagination.Item>
              <Pagination.Item
                disabled={shipments.length < take}
                onClick={() => {
                  handleNext();
                }}
              >
                Next
              </Pagination.Item>
            </Pagination>
          </Spinner>
        </Card>
        <Modal show={modalFilter} onHide={() => showFilterModal(false)} size="md">
          <Modal.Body>
            <Modal.Title>
              <Row>
                <Col xs={6}>
                  <h1>Filters</h1>
                </Col>
                <Col xs={6} className="text-right">
                  <Button disabled={loading || isReset} variant="outline-secondary" onClick={onRefresh}>
                    Clear
                  </Button>
                </Col>
              </Row>
            </Modal.Title>
            <hr />
            <div class="list-group list-group-flush mt-n4 mb-4">
              <div class="list-group-item">
                <Row>
                  <Col className="mt-2">
                    <h4>Groups</h4>
                  </Col>
                  <Col sm="auto">
                    <Dropdown>
                      <Dropdown.Toggle variant="white">{!filters?.groupsFilter ? <span>All</span> : filters?.groupsFilter && <span>{filters?.groupsFilter}</span>}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            onFiltersChange('group', '');
                          }}
                        >
                          All
                        </Dropdown.Item>
                        {groups &&
                          groups.map((pharmacy, i) => {
                            return (
                              <>
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => {
                                    onFiltersChange('group', pharmacy);
                                  }}
                                >
                                  {pharmacy.name}
                                </Dropdown.Item>
                              </>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </div>
            </div>
            <div class="list-group list-group-flush mt-n4 mb-4">
              <div class="list-group-item">
                <Row>
                  <Col className="mt-2">
                    <h4>Shippers</h4>
                  </Col>
                  <Col sm="auto">
                    {visible && (
                      <Dropdown>
                        <Dropdown.Toggle variant="white">{!filters?.shipperFilter ? <span>All</span> : filters?.shipperFilter && <span>{filters?.shipperFilter}</span>}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {filters?.groupsFilter?.length <= 0 && (
                            <Dropdown.Item
                              onClick={() => {
                                onFiltersChange('shipper', '');
                              }}
                            >
                              All
                            </Dropdown.Item>
                          )}
                          {shipperList &&
                            shipperList.map((shipper, i) => {
                              return (
                                <>
                                  <Dropdown.Item
                                    key={i}
                                    onClick={() => {
                                      onFiltersChange('shipper', shipper);
                                    }}
                                  >
                                    {shipper.name}
                                  </Dropdown.Item>
                                </>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
            <div class="list-group list-group-flush mt-n4 mb-4">
              <div class="list-group-item">
                <Row>
                  <Col className="mt-2">
                    <h4>Status</h4>
                  </Col>
                  <Col sm="auto">
                    <Dropdown>
                      <Dropdown.Toggle variant="white">{!filters?.statusFilter ? <span>All</span> : <span>{filters?.statusFilter === 'Exception' ? 'Cancelled' : filters?.statusFilter}</span>}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            onFiltersChange('status', '');
                          }}
                        >
                          All
                        </Dropdown.Item>
                        {shipmentStatus &&
                          shipmentStatus.map((status, i) => {
                            return (
                              <>
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => {
                                    onFiltersChange('status', status);
                                  }}
                                >
                                  {status.name === 'Exception' ? 'Cancelled' : status.name}
                                </Dropdown.Item>
                              </>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </div>
            </div>
            <div class="list-group list-group-flush mt-n4 mb-4">
              <div class="list-group-item">
                <Row>
                  <Col className="mt-2">
                    <h4>Delivery Instructions</h4>
                  </Col>
                  <Col sm="auto">
                    <Dropdown>
                      <Dropdown.Toggle variant="white">{!filters?.instructionsFilter ? <span>All</span> : <span>{filters?.instructionsFilter}</span>}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            onFiltersChange('instructions', '');
                          }}
                        >
                          All
                        </Dropdown.Item>
                        {deliveryInstructions &&
                          deliveryInstructions.map((instruction, i) => {
                            return (
                              <>
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => {
                                    onFiltersChange('instructions', instruction);
                                  }}
                                >
                                  {instruction.name}
                                </Dropdown.Item>
                              </>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </div>
            </div>
            <Button disabled={loading} variant="primary" onClick={onApplyFilter}>
              Apply filter
            </Button>

            <Button
              disabled={loading}
              variant="outline-secondary"
              onClick={() => {
                showFilterModal(false);
              }}
              className="ml-2"
            >
              Cancel
            </Button>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(ShipmentList);

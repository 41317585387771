/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Footer from '../../components/Footer';
import Spinner from '../../components/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import InfoCard from '../../components/InfoCard';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap-daterangepicker/daterangepicker.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { getAllShippers, shipmentStatusList } from '../../services/index';
import { updateToken, toUtcDate, toTimeZone, getDateTimeDiffernce } from '../../Utils';

let dayjs = require('dayjs');
let utc = require('dayjs/plugin/utc');
let localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(localizedFormat);

const TodaysDeliveries = (props) => {
  const take = 25;
  const timeoutRef = useRef(null);
  const [skip, setSkip] = useState(0);
  const [timeout, updateTimeout] = useState(0);
  const [loading, showSpinner] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [keywordFilter, setKeyword] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [shipmentStatus, setShipmentStatus] = useState([]);
  const [shipmentsCounts, setShipmentsCounts] = useState([]);
  const [shipmentByStatus, setShipmentByStatus] = useState({});

  useEffect(() => {
    getShipmentStatusList();
  }, []);

  useEffect(() => {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getShipments();
    }, timeout);
  }, [keywordFilter, statusFilter, skip]);

  useEffect(() => {
    getShipmentsCount(props.deliveries);
  }, [props.deliveries]);

  useEffect(() => {
    setPagination();
  }, [statusFilter]);

  const getShipmentStatusList = async () => {
    const response = await shipmentStatusList();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const statusList = response?.data?.filter((item) => item !== 'Info Received' && item !== 'Processing');
        const shipmentStatus = statusList?.map((item, index) => {
          return { id: index, name: item };
        });
        setShipmentStatus(shipmentStatus);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShipments = async () => {
    showSpinner(true);
    const searchKeyword = keywordFilter?.length > 0 ? encodeURI(keywordFilter?.trim()) : '';
    const url = `dashboard/shipment-today?keyword=${searchKeyword}&status=${statusFilter.toLowerCase()}&yesterday_from_date=${toUtcDate(`${moment().subtract(1, 'day').format('YYYY-MM-DD')}T00:00:00`)}&yesterday_to_date=${toUtcDate(`${moment().subtract(1, 'day').format('YYYY-MM-DD')}T23:59:59`)}&today_from_date=${toUtcDate(`${moment().format('YYYY-MM-DD')}T00:00:00`)}&today_to_date=${toUtcDate(`${moment().format('YYYY-MM-DD')}T23:59:59`)}&take=${take}&skip=${skip}`;
    props.parentCallback(keywordFilter, statusFilter);
    const response = await getAllShippers(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shipments = response?.data;

        await setShipments(shipments);
        showSpinner(false);
        updateTimeout(1500);
      }
    } else {
      if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
        await updateToken();
      }
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
    }
  };

  const getShipmentsCount = async (Updatedshipments) => {
    const countsList = {};
    if (isEmpty(Updatedshipments)) {
      countsList.ready_for_pickup = 0;
      countsList.out_for_delivery = 0;
      countsList.exception = 0;
      countsList.delivered = 0;
      countsList.returned = 0;
      countsList.out_for_return = 0;
      countsList.deliveryPercentage = 0;
    } else {
      countsList.ready_for_pickup = Updatedshipments?.ready_for_pickup;
      countsList.out_for_delivery = Updatedshipments?.out_for_delivery;
      countsList.exception = Updatedshipments?.exception;
      countsList.delivered = Updatedshipments?.delivered;
      countsList.out_for_return = Updatedshipments?.out_for_return;
      countsList.returned = Updatedshipments?.returned;
      const completedOrders = Number(Updatedshipments?.delivered) + Number(Updatedshipments?.exception) + Number(Updatedshipments?.out_for_return) + Number(Updatedshipments?.returned);
      const deliveryRate = Math.round((completedOrders / Number(Updatedshipments?.total)) * 100);
      countsList.deliveryPercentage = deliveryRate > 0 ? deliveryRate : 0;
    }
    const shipmentsCounts = [
      {
        value: 'Ready For Pickup',
        color: '#2C7BE5',
        count: Number(countsList.ready_for_pickup),
      },
      {
        value: 'Out for delivery',
        color: '#E8C50D',
        count: Number(countsList.out_for_delivery),
      },
      {
        value: 'Delivered',
        color: '#34A443',
        count: Number(countsList.delivered),
      },
      {
        value: 'Cancelled',
        color: '#E63757',
        count: Number(countsList.exception),
      },
      {
        value: 'Out for Return',
        color: '#FB8137',
        count: Number(countsList.out_for_return),
      },
      {
        value: 'Returned',
        color: '#885AEA',
        count: Number(countsList.returned),
      },
    ];
    setShipmentsCounts(shipmentsCounts);
    setShipmentByStatus(countsList);
  };

  const setPagination = () => {
    if (shipments?.length <= 4) {
      setSkip(0);
    }
  };

  const handlePrevious = () => {
    setSkip(skip - take);
  };

  const handleNext = () => {
    setSkip(skip + take);
  };

  const onSearch = ({ target: { value } }) => {
    setSkip(0);
    setKeyword(value);
  };

  const onRefresh = () => {
    setKeyword('');
    setStatusFilter('');
  };

  const totalShipments = shipmentsCounts?.reduce((count, shipment) => count + shipment.count, 0);

  return (
    <>
      <Container className="mt-5">
        <Spinner display={loading}>
          <Card className="d-flex flex-row align-items-center justify-content-start">
            <Col className="mt-4 flex-grow-0">
              <InfoCard title="Completed (%)">{`${shipmentByStatus?.deliveryPercentage} %`}</InfoCard>
            </Col>
            <Col>
              <Row className="d-flex justify-content-between">
                <Col>
                  <span className="progress_status progress_status_ready">Ready for pickup {shipmentByStatus?.ready_for_pickup}</span>
                </Col>
                <Col className="text-center">
                  <span className="progress_status progress_status_out">Out for delivery {shipmentByStatus?.out_for_delivery}</span>
                </Col>
                <Col>
                  <span className="progress_status progress_status_delivered float-right">Delivered {shipmentByStatus?.delivered}</span>
                </Col>
              </Row>
              <ProgressBar style={{ height: '0.6rem' }} className="mt-4">
                {shipmentsCounts.map((category, index) => (
                  <OverlayTrigger
                    key={index}
                    placement={'top'}
                    overlay={
                      <Tooltip id={index}>
                        <strong>{category?.count > 0 ? `${category?.value} ${category?.count}` : null}</strong>
                      </Tooltip>
                    }
                  >
                    <ProgressBar isChild={true} max={totalShipments} now={category?.count} key={index} style={{ backgroundColor: category?.count > 0 ? category?.color : 'transparent' }} />
                  </OverlayTrigger>
                ))}
              </ProgressBar>
            </Col>
          </Card>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  <InputGroup className="input-group-flush">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fe fe-search"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl placeholder="eg: shipment number, sender, recipient, delivery instructions, reference number or order number" value={keywordFilter} onChange={onSearch} />
                  </InputGroup>
                </Col>
                <Col sm="auto">
                  <Row>
                    <Col sm="auto" className="p-1">
                      <Dropdown>
                        <Dropdown.Toggle variant="white">{!statusFilter ? <span>All Status</span> : <span>Status: {statusFilter === 'Exception' ? 'Cancelled' : statusFilter}</span>}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setStatusFilter('');
                            }}
                          >
                            All
                          </Dropdown.Item>
                          {shipmentStatus &&
                            shipmentStatus.map((shipment, i) => {
                              return (
                                <>
                                  <Dropdown.Item
                                    key={i}
                                    onClick={() => {
                                      setStatusFilter(shipment.name);
                                    }}
                                  >
                                    {shipment.name === 'Exception' ? 'Cancelled' : shipment.name}
                                  </Dropdown.Item>
                                </>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                    <Col sm="auto" className="p-1">
                      <Button variant="white" onClick={onRefresh}>
                        <i className="fe fe-refresh-cw"></i>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>

            <Table className="table-hover table-borderless table-thead-bordered" responsive size="sm">
              <thead>
                <tr>
                  <th className="text-center">Shipment#</th>
                  <th>Sender</th>
                  <th>Recipient</th>
                  <th>Service Type</th>
                  <th className="text-center">Elapsed Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              {shipments.map((shipment, index) => {
                const fedExServiceType = shipment?.service_type === 'GROUND_HOME_DELIVERY' ? 'Ground Home Delivery' : shipment?.service_type === 'STANDARD_OVERNIGHT' ? 'Standard Overnight' : shipment?.service_type;
                return (
                  <tbody key={index}>
                    <tr style={{ cursor: 'pointer' }} onClick={() => window.open(`shipment/${shipment.id}`, "_blank")}>
                      <td className="text-center">
                        {shipment.number}
                        {shipment?.batch_number > 0 && <div className="small text-muted">{`Batch - ${shipment.batch_number}`}</div>}
                      </td>
                      <td>
                        {shipment.ship_from.shipper_name}
                        <div className="small text-muted">
                          {shipment.ship_from.city}, {shipment.ship_from.state}
                        </div>
                      </td>
                      <td>
                        {shipment.ship_to.contact_name}
                        <div className="small text-muted">
                          {shipment.ship_to.city}, {shipment.ship_to.state}
                        </div>
                      </td>
                      <td>
                        {fedExServiceType}
                        {shipment?.special_instruction?.length > 0 && (
                          <span className="text-warning ml-2" data-toggle="tooltip" data-placement="top" title={shipment?.special_instruction}>
                            <i className="fe fe-alert-triangle"></i>
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {shipment.delivery_date && shipment.create_date ? '' : `${getDateTimeDiffernce(toTimeZone(moment(), 'lll', shipment?.ship_from?.timezone), toTimeZone(shipment?.create_date, 'lll', shipment?.ship_from?.timezone))}`}

                        {shipment.delivery_date && shipment.create_date && <div className="small text-muted">{`${getDateTimeDiffernce(toTimeZone(shipment?.delivery_date, 'lll', shipment?.ship_from?.timezone), toTimeZone(shipment?.create_date, 'lll', shipment?.ship_from?.timezone))} (TAT)`}</div>}
                        <div className="small text-muted">{shipment?.carrier?.name}</div>
                      </td>
                      <td>
                        {shipment.status === 'In Transit' && <span className="h3 text-warning mr-2">●</span>}
                        {shipment.status === 'Info Received' && <span className="h3 text-primary mr-2">●</span>}
                        {shipment.status === 'Processing' && <span className="h3 text-primary mr-2">●</span>}
                        {shipment.status === 'Ready for Pickup' && <span className="h3 text-primary mr-2">●</span>}
                        {shipment.status === 'Out for Delivery' && <span className="h3 text-warning mr-2">●</span>}
                        {shipment.status === 'Delivered' && <span className="h3 text-success mr-2">●</span>}
                        {shipment.status === 'Exception' && <span className="h3 text-danger mr-2">●</span>}
                        {shipment.status === 'Out for Return' && (
                          <span className="h3 mr-2" style={{ color: 'rgb(251,129,55)' }}>
                            ●
                          </span>
                        )}
                        {shipment.status === 'Returned' && (
                          <span className="h3 mr-2" style={{ color: 'rgb(136,90,234)' }}>
                            ●
                          </span>
                        )}
                        {shipment.status === 'Exception' ? 'Cancelled' : shipment.status}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
            <Pagination size="sm" className="justify-content-center">
              <Pagination.Item
                disabled={skip === 0}
                onClick={() => {
                  handlePrevious();
                }}
              >
                Previous
              </Pagination.Item>
              <Pagination.Item disabled={true}>Page {skip / take + 1}</Pagination.Item>
              <Pagination.Item
                disabled={shipments.length < take}
                onClick={() => {
                  handleNext();
                }}
              >
                Next
              </Pagination.Item>
            </Pagination>
          </Card>
        </Spinner>
      </Container>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(TodaysDeliveries);
